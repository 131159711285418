import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { SettingsService } from 'src/app/settings.service';
declare var $: any;
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})
export class SettingsModalComponent implements OnInit {
  permissions: any = {};
  hasAccess!: boolean;
  userRole!: string;
  activeTab: string = 'currentPlan';

  constructor(
    private settingsService: SettingsService,
    public authService: AuthService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('es');
    translate.use('es');
  }

  async ngOnInit(): Promise<void> {
    this. loadPermissionsAndRole()
  }

  async loadPermissionsAndRole(): Promise<void> {
    try {
      this.permissions = await this.authService.getUserPermissions();
      this.userRole = await this.authService.getUserRole();
      if (this.userRole === 'Owner' || this.userRole === 'Admin' || this.permissions.userRoles === true) {
        this.hasAccess = true;
      } else {
        this.hasAccess = false;
      }
      if (this.permissions.generalInfo) {
        this.activeTab = 'step1';
      }

    } catch (error) {
      console.error('Error al obtener permisos o rol del usuario:', error);
    }
  }

  ngOnDestroy() {
    $('#settingsModal').modal('hide');
  }

  closeModal() {
    $('#settingsModal').modal('hide');
  }

  /**
   * This function returns a boolean value that is stored in the settingsService
   * @returns A boolean value.
   */
  getListingUsers(): boolean {
    return this.settingsService.getListingUsers();
  }

  /**
   * This function returns the value of the editingOwner property of the settingsService
   * @returns A boolean value.
   */
  getEditingOwner(): boolean {
    return this.settingsService.getEditingOwner();
  }

  /**
   * If the user is editing a user, return true
   * @returns A boolean value.
   */
  isEditingUser(): boolean {
    return this.settingsService.isEditingUser();
  }

  /**
   * It returns the editing user from the settings service
   * @returns The editing user.
   */
  getEditingUser(): string {
    return this.settingsService.getEditingUser();
  }

  /**
   * The function calls the setListingUsers() function in the settingsService
   */
  setListingUsers(): void {
    this.settingsService.setListingUsers();
  }

  /**
   * It returns a boolean value that is stored in the settingsService
   * @returns A boolean value.
   */
  getCreatingUser(): boolean {
    return this.settingsService.getCreatingUser();
  }

  /**
   * It sets the creatingUser property of the settingsService to true
   */
  setCreatingUser(): void {
    this.settingsService.setCreatingUser();
  }

  /**
   * This function returns a boolean value that is stored in the settingsService
   * @returns A boolean value.
   */
  getListingCategories(): boolean {
    return this.settingsService.getListingCategories();
  }

  getListingSubCategories(): boolean {
    return this.settingsService.getListingSubCategories();
  }

  /**
   * If the user is editing a category, return true
   * @returns A boolean value.
   */
  isEditingCategory(): boolean {
    return this.settingsService.isEditingCategory();
  }

  isEditingSubCategory(): boolean {
    return this.settingsService.isEditingSubCategory();
  }

  /**
   * It returns the editing category from the settings service
   * @returns The editing category.
   */
  getEditingCategory(): string {
    return this.settingsService.getEditingCategory();
  }

  getEditingSubCategory(): string {
    return this.settingsService.getEditingSubCategory();
  }

  /**
   * The function calls the setListingCategories() function in the settingsService
   */
  setListingCategories(): void {
    this.settingsService.setListingCategories();
  }

  /**
   * It returns a boolean value that is stored in the settingsService
   * @returns A boolean value.
   */
  getCreatingCategory(): boolean {
    return this.settingsService.getCreatingCategory();
  }

  getCreatingSubCategory(): boolean {
    return this.settingsService.getCreatingSubCategory();
  }

  /**
   * It sets the creatingCategory property of the settingsService to true
   */
  setCreatingCategory(): void {
    this.settingsService.setCreatingCategory();
  }

  /* COLLECTIONS */
  getListingCollections(): boolean {
    return this.settingsService.getListingCollections();
  }

  isEditingCollection(): boolean {
    return this.settingsService.isEditingCollection();
  }

  getEditingCollection(): string {
    return this.settingsService.getEditingCollection();
  }

  setListingCollections(): void {
    this.settingsService.setListingCollections();
  }

  getCreatingCollection(): boolean {
    return this.settingsService.getCreatingCollection();
  }

  setCreatingCollection(): void {
    return this.settingsService.setCreatingCollection();
  }

  /* TIMEKIT */


  /* SUPPLIERS */
  getListingSuppliers(): boolean {
    return this.settingsService.getListingSuppliers();
  }

  isEditingSupplier(): boolean {
    return this.settingsService.isEditingSupplier();
  }

  getEditingSupplier(): string {
    return this.settingsService.getEditingSupplier();
  }

  setListingSuppliers(): void {
    this.settingsService.setListingSuppliers();
  }

  getCreatingSupplier(): boolean {
    return this.settingsService.getCreatingSupplier();
  }

  setCreatingSupplier(): void {
    return this.settingsService.setCreatingSupplier();
  }

  /* SHOP POINTS */
  getListingShopPoints(): boolean {
    return this.settingsService.getListingShopPoints();
  }

  isEditingShopPoint(): boolean {
    return this.settingsService.isEditingShopPoint();
  }

  getEditingShopPoint(): string {
    return this.settingsService.getEditingShopPoint();
  }

  setListingShopPoints(): void {
    this.settingsService.setListingShopPoints();
  }

  getCreatingShopPoint(): boolean {
    return this.settingsService.getCreatingShopPoint();
  }

  setCreatingShopPoint(): void {
    return this.settingsService.setCreatingShopPoint();
  }

  /* PAYMENT METHOD */
  getListingPaymentMethods(): boolean {
    return this.settingsService.getListingPaymentMethods();
  }

  isEditingPaymentMethod(): boolean {
    return this.settingsService.isEditingPaymentMethod();
  }

  getEditingPaymentMethod(): string {
    return this.settingsService.getEditingPaymentMethod();
  }

  setListingPaymentMethods(): void {
    this.settingsService.setListingPaymentMethods();
  }

  getCreatingPaymentMethod(): boolean {
    return this.settingsService.getCreatingPaymentMethod();
  }

  setCreatingPaymentMethod(): void {
    return this.settingsService.setCreatingPaymentMethod();
  }

}
