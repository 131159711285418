
<full-calendar [options]="calendarOptions" id="calendar" class="container main-container"></full-calendar>
<p>{{ data }}</p>
<!-- Modal -->
<div id="appointmentModal" [ngClass]="{'visible': isModalOpen}" class="modal-overlay">

  <div class="modal-content">
    <div class="header">
      <button (click)="closeModal()" class="btn-close"></button>
      <h2 class="modal-title title">Detalles de la cita</h2>
    </div>

    <p><strong>Nombre:</strong> {{ selectedAppointment?.customers?.[0]?.name || 'No disponible' }}</p>
    <p><strong>Fecha:</strong> {{ getFormattedDate(selectedAppointment?.start) }} </p>
    <p><strong>Hora:</strong> {{ getFormattedTime(selectedAppointment?.start) }} - {{ getFormattedTime(selectedAppointment?.end) }}</p>
    <p><strong>Email:</strong> {{ selectedAppointment?.customers?.[0]?.email || 'No disponible' }}</p>
    <p><strong>Teléfono:</strong> {{ selectedAppointment?.customers?.[0]?.telefono || 'No disponible' }}</p>
    <p><strong>Tipo de Cita:</strong> {{ selectedAppointment?.customers?.[0]?.tipocita || 'No disponible' }}</p>
    <p><strong>Lugar de Cita:</strong> {{ selectedAppointment?.customers?.[0]?.lugar_de_cita || 'No disponible' }}</p>


    <div class="buttons">
      <button (click)="updateAppointment()" class="custom-button btn-outline-primary primary-btn">Reagendar</button>
      <button (click)="cancelAppointment()" class="btn btn-primary remove-btn">Cancelar Cita</button>
    </div>

  </div>
</div>

<!-- Modal para disponibilidad de horarios Seguimiento-->
<div class="availability-modal-overlay modal" [ngClass]="{'visible': isAvailabilityModalOpen}">
  <div class="availability-modal-content modal-content">

    <div class="modal-header">
      <h2 class="modal-title-2 title">Selecciona un horario disponible</h2>
      <span class="btn-close" (click)="closeAvailabilityModal()">&times;</span>
    </div>

    <ul class="modal-body">
      <li *ngFor="let slot of availableSlots" class="slot-item">
        <div class="slot-details">
          <div class="slot-info">
            <div class="slot-day"><strong>{{ slot.formattedStart }}</strong></div>
            <span class="slot-time">{{ slot.startTime }} - {{ slot.endTime }}</span>
          </div>
          <button (click)="selectSlot(slot)"
            class="custom-button-2 btn-outline-primary primary-btn">Seleccionar</button>
        </div>
        <hr />
      </li>
    </ul>
    <div *ngIf="!availableSlots || availableSlots.length === 0">
      <p>No hay horarios disponibles.</p>
    </div>
  </div>
</div>



  <!-- Modal de Confirmación para Cancelar Cita -->
<app-confirmation-modal
*ngIf="modalCancelAppointment"
[title]="'¿Seguro que quiere cancelar esta cita?'"
[message]="'Al cancelar la cita, esta ya no estará disponible en el sistema. ¿Desea continuar?'"
[confirmText]="'Cancelar Cita'"
[cancelText]="'Cancelar'"
(response)="handleCancelConfirmation($event)">
</app-confirmation-modal>

<!-- Modal de Éxito -->
<div class="modal-appointment delete-category-confirm-modal" id="deleteAppointmentModal" tabindex="-1" *ngIf="modalSuccess">
<div class="modal-dialog category-modal-dialog">
  <div class="modal-content category-modal-content">
    <div class="modal-body category-modal-body">
      <h3 class="text-center">¡Éxito!</h3>
      <p class="text-center">¡La cita fue cancelada exitosamente!</p>
    </div>
    <div class="modal-buttons">
      <button type="button" class="btn btn-confirm" (click)="closeModalAppointment()">Cerrar</button>
    </div>
  </div>
</div>
</div>
