<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-order">
    <div class="header">
      <h1 class="title">{{'ORDER' | translate}} {{saleInfo.saleShortCode}}</h1>
      <app-back-arrow [route]="'/orders'"></app-back-arrow>
    </div>
    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
  </div>
    <div *ngIf="!loading" class="form-card-group">
      <div class="form-card info">
        <div class="info-container" *ngIf="!loading">
          <table class="sale-info-container">
            <tr>
              <th>{{ 'SALE_INFORMATION' | translate }}</th>
            </tr>
            <tr>
              <td>{{ 'ORDER_NUMBER' | translate }}: {{ saleInfo.saleShortCode }}</td>
            </tr>
            <tr>
              <td>{{ 'PAYMENT_TYPE' | translate }}: {{ saleInfo.paymentTypeName }}</td>
            </tr>
            <tr *ngIf="saleInfo.giftCardDiscount > 0">
              <td>{{ 'GIFT_CARD_PAYMENT' | translate }}: {{ appComponent.formatMoney(saleInfo.giftCardDiscount) }}</td>
            </tr>
            <tr *ngFor="let giftCard of giftCardPurchase">
              <td>{{ 'CARD_AVAILABLE_BALANCE' | translate }}: {{ appComponent.formatMoney(giftCard.balance) }}</td>
            </tr>
            <tr *ngFor="let giftCard of giftCardPurchase">
              <td>{{ 'CARD_CODE' | translate }}: {{ giftCard.code }}</td>
            </tr>
            <tr>
              <td>
                {{'AMOUNT' | translate}} Total:
                {{ appComponent.formatMoney(saleInfo.saleEndPrice) }}
              </td>
            </tr>
            <tr>
              <td>
                  <ng-container *ngIf="giftCards.length === 1 && items.length === 0">
                   {{ formatDate(saleInfo.saleDate) }}
                  </ng-container>
                  <ng-container *ngIf="!(giftCards.length === 1 && items.length === 0)">
                      {{ saleInfo.saleDate }}
                  </ng-container>
              </td>
          </tr>
            <tr>
              <td>{{'SHOP_POINTS' | translate}}: {{ saleInfo.shopPointName }}</td>
            </tr>
            <tr>
              <td>
                {{ 'DELIVERY' | translate }}:
                {{
                saleInfo.expressShipping == 0
                ? "Retirar en tienda"
                : "Envío a domicilio"
                }}
              </td>
            </tr>
            <tr *ngIf="saleInfo.paymentTypeCode === 'SINPE' || saleInfo.paymentTypeCode === 'BankTransfer'">
              <td>
                <div class="voucher-container">
                  <div>{{ 'RECEIPT' | translate }}:</div>
                  <button data-bs-toggle="modal" data-bs-target="#voucherModal">
                    {{'SEE' | translate}}
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="state-container">
                  <div>{{'STATUS' | translate}}:</div>
                  <select class="form-select input-form-select" id="shippingType" [ngModel]="currentSaleState"
                      (change)="saleStateChange($event)">
                      <option *ngFor="let saleStateId of getObjectKeys(saleStateName)" value="{{ saleStateId }}">
                          {{ saleStateName[saleStateId] }}
                      </option>
                  </select>
                  <button [hidden]="!saleStateChanged" (click)="changeSaleState()">
                      <i class="feather" data-feather="check" style="color: green"></i>
                  </button>
                  <button [hidden]="!saleStateChanged" (click)="cancelSaleStateChange()">
                      <i class="feather" data-feather="x" style="color: red"></i>
                  </button>
                  <div class="loading-sale-state" *ngIf="loadingSaleStateChange">
                      <div class="spinner-border" role="status"></div>
                  </div>
              </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div *ngIf="!loading" class="form-card info">
        <table class="client-info-container">
          <tr>
            <th>{{ 'CUSTOMER_INFORMATION' | translate }}</th>
          </tr>
          <tr>
            <td>{{'NAME' | translate}}: {{ saleInfo.clientName }}</td>
          </tr>
          <tr>
            <td>{{ 'IDENTIFICATION' | translate }}: {{ saleInfo.clientId }}</td>
          </tr>
          <tr>
            <td>{{'EMAIL' | translate}}: {{ saleInfo.clientEmail }}</td>
          </tr>
          <tr>
            <td>{{'PHONE' | translate}}: {{ saleInfo.clientPhone }}</td>
          </tr>
          <tr *ngIf="saleInfo.expressShipping != 0">
            <td>
              {{ 'ADDRESS' | translate }}: {{saleInfo.exactDirection }}
            </td>
          </tr>
          <tr>
            <td *ngIf="saleInfo.expressShipping === 1" class="wazeLink">
              <a [href]="saleInfo.wazeLink" target="_blank">Waze</a>
              <button (click)="copyWazeLink()">
                <i class="feather" data-feather="copy"></i>
              </button>
            </td>
          </tr>
          <tr *ngIf="saleInfo.expressShipping != 0">
            <td>{{ 'NOTES' | translate }}: {{ saleInfo.notes }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div *ngIf="(!giftCards || giftCards.length === 0) && items && items.length > 0 && !loading"  class="form-card">
      <h3 class="subtitle">{{'ITEMS' | translate}}</h3>
      <table class="items-container table table-bordered">
          <thead>
              <th scope="col" class="table-start">{{'ITEM' | translate}}</th>
              <th scope="col">{{'PRICE' | translate}}</th>
              <th scope="col">{{'QUANTITY' | translate}}</th>
              <th scope="col">{{'CODE' | translate}}</th>
              <th scope="col">{{'VARIANTS' | translate}}</th>
              <th scope="col">{{'SHOP_POINTS' | translate}}</th>
              <th scope="col" class="table-end">{{'IMAGE' | translate}}</th>
          </thead>
          <tbody>
              <tr *ngFor="let item of items">
                  <td>{{ item.productName }}</td>
                  <td>{{ appComponent.formatMoney(item.saleItemUnitCost) }}</td>
                  <td>{{ item.saleItemQuantity }}</td>
                  <td>{{ item.itemCode }}</td>
                  <td>
                      <table>
                          <tr *ngFor="let variant of item.variants">
                              <td>{{ variant.variantTypeName }}</td>
                              <td>{{ variant.variantValueName }}</td>
                          </tr>
                          <tr *ngIf="!item.variants">
                              <td>{{ 'WITHOUT_VARIANTS' | translate }}</td>
                          </tr>
                      </table>
                  </td>
                  <td>{{ item.shopPointName }}</td>
                  <td>
                      <img *ngIf="item.imageURL" [src]="appComponent.randomCacheID(item.imageURL)" />
                      <span *ngIf="!item.imageURL">{{'NO_IMAGE' | translate}}</span>
                  </td>
              </tr>
          </tbody>
      </table>
      <div class="loading" *ngIf="loading">
          <div class="spinner-border" role="status"></div>
      </div>
  </div>

  <div *ngIf="!loading && giftCards && giftCards.length > 0 && (!items || items.length === 0)"  class="form-card">
      <h5 class="subtitle">{{'GIFT_CARDS' | translate}}</h5>
      <table class="items-container table table-bordered">
          <thead>
              <th scope="col" class="table-start">{{'NAME' | translate}}</th>
              <th scope="col">{{'PRICE' | translate}}</th>
              <th scope="col">{{'QUANTITY' | translate}}</th>
          <th scope="col">{{'CODE' | translate}}</th>
              <th scope="col">{{'STATUS' | translate}}</th>
              <th scope="col" class="table-end">{{'IMAGE' | translate}}</th>
          </thead>
          <tbody>
              <tr *ngFor="let giftCard of giftCards">
                  <td>{{ giftCard.name }}</td>
                  <td>{{ appComponent.formatMoney(giftCard.saleGiftCardUnitCost)}}</td>
                  <td>{{ giftCard.saleGiftCardQuantity }}</td>
                  <td>{{ giftCard.code }}</td>
                  <td>{{ giftCard.status === 0 ? ('INACTIVE' | translate) : (giftCard.status === 1 ? ('ACTIVE' | translate) : ('PENDING' | translate)) }}</td>
                  <td>
                      <img *ngIf="giftCard.images" [src]="appComponent.randomCacheID(giftCard.images)" />
                      <span *ngIf="!giftCard.images">{{'NO_IMAGE' | translate}}</span>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>

  <div *ngIf="giftCards && giftCards.length > 0 && items && items.length > 0" class="form-card">
      <h5 class="subtitle">{{'GIFT_CARDS' | translate}}</h5>
      <table class="items-container table table-bordered">
          <thead>
              <th scope="col" class="table-start">{{'NAME' | translate}}</th>
              <th scope="col">{{'PRICE' | translate}}</th>
              <th scope="col">{{'QUANTITY' | translate}}</th>
              <th scope="col">{{'CODE' | translate}}</th>
              <th scope="col">{{'STATUS' | translate}}</th>
              <th scope="col" class="table-end">{{'IMAGE' | translate}}</th>
          </thead>
          <tbody>
              <tr *ngFor="let giftCard of giftCards">
                  <td>{{ giftCard.name }}</td>
                  <td>{{ appComponent.formatMoney(giftCard.saleGiftCardUnitCost)}}</td>
                  <td>{{ giftCard.saleGiftCardQuantity }}</td>
                  <td>{{ giftCard.code }}</td>
                  <td> {{ giftCard.status === 0 ? ('INACTIVE' | translate) : (giftCard.status === 1 ? ('ACTIVE' | translate) : ('PENDING' | translate)) }}</td>
                  <td>
                      <img *ngIf="giftCard.images" [src]="appComponent.randomCacheID(giftCard.images)" />
                      <span *ngIf="!giftCard.images">{{'NO_IMAGE' | translate}}</span>
                  </td>
              </tr>
          </tbody>
      </table>

      <h3 class="subtitle">{{ 'ITEMS' | translate }}</h3>
      <table class="items-container table table-bordered">
          <thead>
              <th scope="col" class="table-start">{{ 'ITEM' | translate }}</th>
              <th scope="col">{{'PRICE' | translate}}</th>
              <th scope="col">{{'QUANTITY' | translate}}</th>
              <th scope="col">{{'CODE' | translate}}</th>
              <th scope="col">{{'VARIANTS' | translate}}</th>
              <th scope="col">{{'SHOP_POINTS' | translate}}</th>
              <th scope="col" class="table-end">{{'IMAGE' | translate}}</th>
          </thead>
          <tbody>
              <tr *ngFor="let item of items">
                  <td>{{ item.productName }}</td>
                  <td>{{ appComponent.formatMoney(item.saleItemUnitCost) }}</td>
                  <td>{{ item.saleItemQuantity }}</td>
                  <td>{{ item.itemCode }}</td>
                  <td>
                      <table>
                          <tr *ngFor="let variant of item.variants">
                              <td>{{ variant.variantTypeName }}</td>
                              <td>{{ variant.variantValueName }}</td>
                          </tr>
                          <tr *ngIf="!item.variants">
                              <td>{{ 'WITHOUT_VARIANTS' | translate }}</td>
                          </tr>
                      </table>
                  </td>
                  <td>{{ item.shopPointName }}</td>
                  <td>
                      <img *ngIf="item.imageURL" [src]="appComponent.randomCacheID(item.imageURL)" />
                      <span *ngIf="!item.imageURL">{{'NO_IMAGE' | translate}}</span>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>

  <div *ngIf="!loading" class="form-card">
  <h3 class="subtitle">{{ 'PAYMENTS' | translate }}</h3>
  <table class="items-container table table-bordered">
      <thead>
          <th scope="col" class="table-start">ID</th>
          <th scope="col">{{'AMOUNT' | translate}}</th>
          <th scope="col">{{ 'PAYMENT_DATE' | translate }}</th>
          <th scope="col" class="table-end">{{'AMOUNT' | translate}} total</th>
      </thead>
      <tbody>
          <tr *ngFor="let payment of salePayment">
            <td>{{ payment.salePaymentId }}</td>
            <td>{{ appComponent.formatMoney(payment.salePaymentAmount) }}</td>
            <td>{{ formatDate(payment.salePaymentDate) }}</td>
            <td>{{ appComponent.formatMoney(payment.saleTotalAmount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
  <div class="modal" tabindex="-1" id="voucherModal" *ngIf="saleInfo.paymentTypeCode != 'CreditCard'">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h5 class="modal-title">Modal title</h5> -->
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img [src]="saleInfo.saleVoucher" />
        </div>
        <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
      </div>
    </div>
  </div>
</div>
