import { Component, OnInit } from '@angular/core';
import { ThemingService, ShopTheming } from 'src/app/theming.service';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import * as feather from 'feather-icons';
declare var $: any;

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent implements OnInit {
  loading = false;
  loadingLocation = false;
  loadingInfo = false;
  loadingSocial = false;
  isEditingGeneral = false;
  isEditingLocation = false;
  isEditingSocial = false;

  shopInfo: any;
  shopSocialMedia: any;
  currencies: any;

  countries: any;
  geoLocation1: any;
  geoLocation2: any;
  geoLocation3: any;

  currentCurrencyCode: any = "";

  public shopTheme: ShopTheming[] = [];
  constructor(
    public apiService: ApiService,
    public themingService: ThemingService,
    private router: Router
  ) {
    this.shopTheme = this.themingService.shopTheming;
  }

  ngOnInit(): void {
    console.log("shopTheme: ", this.shopTheme);
    this.getShopData();
    this.getCountries();
  }

  ngAfterViewInit() {
    feather.replace();
  }

  switchEditingGeneral() {
    this.isEditingGeneral = !this.isEditingGeneral;
    // this.isEditingGeneral === false ? location.reload() : null;
  }

  switchEditingLocation() {
    this.isEditingLocation = !this.isEditingLocation;
    // this.isEditingLocation === false ? location.reload() : null;
  }

  switchEditingSocial() {
    this.isEditingSocial = !this.isEditingSocial;
    // this.isEditingSocial === false ? location.reload() : null;
  }

  changeCurrency(event: Event){
    const element = event.target as HTMLInputElement;
    this.shopInfo.currencyId = parseInt(element.value);
    let currentCurrency = this.currencies.find((c: any) => c.currencyId === this.shopInfo.currencyId);
    this.currentCurrencyCode = currentCurrency.currencyCode;
  }

  changeCountry(event: Event){
    this.geoLocation1 = [];
    this.geoLocation2 = [];
    this.geoLocation3 = [];
    const countryId = event.target as HTMLInputElement;
    this.shopInfo.countryId = parseInt(countryId.value);
    this.getGeoLocation1(this.shopInfo.countryId);
    this.shopInfo.shopGeoLocation1Id = 0;
    this.shopInfo.shopGeoLocation2Id = 0;
    this.shopInfo.shopGeoLocation3Id = 0;
  }

  changeGeoLocation1(event: Event){
    this.geoLocation2 = [];
    this.geoLocation3 = [];
    const geoLocation1 = event.target as HTMLInputElement;
    this.shopInfo.shopGeoLocation1Id = parseInt(geoLocation1.value);
    this.getGeoLocation2(parseInt(geoLocation1.value));
    this.shopInfo.shopGeoLocation2Id = 0;
    this.shopInfo.shopGeoLocation3Id = 0;
  }

  changeGeoLocation2(event: Event){
    this.geoLocation3 = [];
    const geoLocation2 = event.target as HTMLInputElement;
    this.shopInfo.shopGeoLocation2Id = parseInt(geoLocation2.value);
    this.getGeoLocation3(parseInt(geoLocation2.value));
    this.shopInfo.shopGeoLocation3Id = 0;
  }

  changeGeoLocation3(event: Event){
    const geoLocation3 = event.target as HTMLInputElement;
    this.shopInfo.shopGeoLocation3Id = parseInt(geoLocation3.value);
  }

  getCountries(){
    this.apiService
    .getCountries()
    .then((data: any) => {
      console.log("countries: ",data);
      this.countries = data;
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .finally(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500); 
    }); 
  }
  getCountryName(): string {
    const country = this.countries?.find((c: any) => c.countryId === this.shopInfo.countryId);
    return country ? country.countryName : 'N/A';
  }

  getProvinceName(): string {
    const province = this.geoLocation1?.find((geo: any) => geo.geolocation1Id === this.shopInfo.shopGeoLocation1Id);
    return province ? province.geolocation1Name : 'N/A';
  }
  
  getCantonName(): string {
    const canton = this.geoLocation2?.find((geo: any) => geo.geolocation2Id === this.shopInfo.shopGeoLocation2Id);
    return canton ? canton.geolocation2Name : 'N/A';
  }
  
  getDistrictName(): string {
    const district = this.geoLocation3?.find((geo: any) => geo.geolocation3Id === this.shopInfo.shopGeoLocation3Id);
    return district ? district.geolocation3Name : 'N/A';
  }
  
  

  getGeoLocation1(geoLocationId: number){
    this.apiService
    .getGeoLocation(1,geoLocationId)
    .then((data: any) => {
      console.log("geoloc: ",data);
      this.geoLocation1 = data;
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .finally(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500); 
    }); 
  }

  getGeoLocation2(geoLocationId: number){
    this.apiService
    .getGeoLocation(2,geoLocationId)
    .then((data: any) => {
      console.log("geoloc2: ",data);
      this.geoLocation2 = data;
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .finally(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500); 
    }); 
  }

  getGeoLocation3(geoLocationId: number){
    this.apiService
    .getGeoLocation(3,geoLocationId)
    .then((data: any) => {
      console.log("geoloc3: ",data);
      this.geoLocation3 = data;
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .finally(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500); 
    }); 
  }

  getShopData(){
    this.apiService
    .getShopData(this.themingService.shopId)
    .then((data: any) => {
      console.log("shop data: ",data);
      this.shopInfo = data.info[0];
      this.shopSocialMedia = data.socialMedia;
      this.currencies = data.currency;
      console.log("currencyId: ",this.shopInfo.currencyId)
      if(this.currencies){
        let currentCurrency = this.currencies.find((c: any) => c.currencyId === this.shopInfo.currencyId);
        this.currentCurrencyCode = currentCurrency.currencyCode;
      }
      if(this.shopInfo.countryId > 0){
        this.getGeoLocation1(this.shopInfo.countryId);
      }
      if(this.shopInfo.shopGeoLocation1Id > 0){
        this.getGeoLocation2(this.shopInfo.shopGeoLocation1Id);
      }
      if(this.shopInfo.shopGeoLocation2Id > 0){
        this.getGeoLocation3(this.shopInfo.shopGeoLocation2Id);
      }
      if(this.shopInfo.shopGeoLocation3Id > 0){
      }
    })
    .catch((error: Error) => {
      console.error(error);
    })
    .finally(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500); 
    }); 
  }

  updateShopInfo(){
    this.loadingInfo = true;
    this.apiService.updateShopInfo(
      this.themingService.shopId,
      this.shopInfo.shopName,
      this.shopInfo.shopMail,
      this.shopInfo.currencyId
    )
    .then((data: any) => {
      console.log(data);
      // if(data.status === 200){
      //   $('#successSupplierModal').modal('show');
      //   this.loading = false;
      // }
      // else{
      //   $('#errorSupplierModal').modal('show');
      // }
      $('#successShopDataModal').modal('show');
      this.getShopData();
      this.loadingInfo = false;
      this.switchEditingGeneral();
    })
    .catch((error) => {
      console.error(error);
      this.loadingInfo = false;
    });
  }

  updateShopSocialMedia(){
    this.loadingSocial = true;
    let socialMediaData = this.shopSocialMedia.map((socialMedia: any) => ({
      socialMediaName: socialMedia.socialMediaName,
      socialMediaURL: socialMedia.socialMediaURL
    }));
    console.log(socialMediaData)
    console.log(JSON.stringify(socialMediaData))
    this.apiService.updateShopSocialMedia(
      this.themingService.shopId,
      socialMediaData
    )
    .then((data: any) => {
      console.log(data);
      // if(data.status === 200){
      //   $('#successSupplierModal').modal('show');
      //   this.loading = false;
      // }
      // else{
      //   $('#errorSupplierModal').modal('show');
      // }
      $('#successShopDataModal').modal('show');
      this.getShopData();
      this.loadingSocial = false;
      this.switchEditingSocial();
    })
    .catch((error) => {
      console.error(error);
      this.loadingSocial = false;
    });
  }

  updateShopLocation(){
    this.loadingLocation = true
    this.apiService.updateShopLocation(
      this.themingService.shopId,
      this.shopInfo.countryId,
      this.shopInfo.shopGeoLocation1Id,
      this.shopInfo.shopGeoLocation2Id,
      this.shopInfo.shopGeoLocation3Id
    )
    .then((data: any) => {
      console.log(data);
      // if(data.status === 200){
      //   $('#successSupplierModal').modal('show');
      //   this.loading = false;
      // }
      // else{
      //   $('#errorSupplierModal').modal('show');
      // }
      $('#successShopDataModal').modal('show');
      this.getShopData();
      this.loadingLocation = false;
      this.switchEditingLocation();
    })
    .catch((error) => {
      console.error(error);
      this.loadingLocation = false;
    });
  }

  closeModal(){
    $('#successShopDataModal').modal('hide');
  }

}

