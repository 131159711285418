<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<!-- <div class="container method-btns">
  <button type="button" class="btn btn-outline-primary" (click)="enableInputs()" *ngIf="product.id > 0"
    [hidden]="inputModifyHidden">
    Modificar producto
  </button>
  <button type="button" class="btn btn-outline-primary" (click)="cleanAll(); enableInputs()" *ngIf="product.id > 0">
    Crear nuevo producto
  </button>
</div> -->

<div class="container">
  <div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="container-inventory" *ngIf="!loading">
    <div class="header">
      <h1 class="title">{{'NEW_PRODUCT'  | translate}}</h1>
      <app-back-arrow [route]="'/products'"></app-back-arrow>
    </div>

    <div class="form-card">
      <input type="hidden" id="product_id" name="id" value="{{product.id}}" />

      <!-- <div class="form-label">Imágenes:</div>
      <div class="images-container">
        <div cdkDropList cdkDropListOrientation="horizontal" class="image-list" (cdkDropListDropped)="drop($event)">
          <div class="image-box" *ngFor="let image of productImages.small; let i = index" [cdkDragDisabled]="false"
            cdkDrag>
            <div class="image-placeholder" *cdkDragPlaceholder></div>
            <img [src]="image.imageURL" />
            <button type="button" class="btn btn-danger delete-button" (click)="deletePhoto(i)">
              x
            </button>
            <button type="button" class="btn btn-link download-button" (click)="downloadImage(i)">
              <i class="feather" data-feather="download"></i>
            </button>
          </div>
          <div class="image-box" cdkDragDisabled>
            <button class="add-img" (click)="uploadImage()">+</button>
          </div>
        </div>
      </div> -->

      <div class="form-group-container">
        <div class="form-group couple">
          <label class="form-label is-required" for="product_name">{{'NAME' | translate}}</label>
          <input type="text" class="form-control" id="product_name" name="name" placeholder="{{'PRODUCT_NAME' | translate}}"
            value="{{product.name}}" (keyup)="changeName($event)" [disabled]="inputsDisabled" />
        </div>

        <div class="form-group couple">
          <label class="form-label is-required" for="product_code">{{'CODE' | translate}}</label>
          <input type="text" class="form-control" id="product_code" name="code" placeholder="{{'PRODUCT_CODE' | translate}}"
            value="{{product.code}}" (keyup)="changeCode($event)" [disabled]="inputsDisabled" />
        </div>
      </div>

      <div class="form-group-container">
        <div class="form-group trio">
          <label class="form-label is-required" for="product_category"> {{'CATEGORIES' | translate}}</label>
          <select class="form-select" id="product_category" name="category" (change)="changeCategory($event)">
            <option selected disabled hidden>{{'SELECT_CATEGORY' | translate}}</option>
            <option *ngFor="let category of categories" [value]="category.categoryId">
              {{ category.categoryName }}
            </option>
          </select>
        </div>
        <div class="form-group trio">
          <label class="form-label" for="product_subcategory">{{'SUBCATEGORY' | translate}}</label>
          <select class="form-select" id="product_subcategory" name="subcategory" (change)="changeSubCategory($event)"
            [disabled]="!subCategories || subCategories.length === 0">
            <option value="0">{{'SELECT_SUBCATEGORY' | translate}}</option>
            <ng-container *ngIf="subCategories && subCategories.length > 0; else noSubCategories">
              <option *ngFor="let subCategory of subCategories" [value]="subCategory.subCategoryId">
                {{ subCategory.subCategoryName }}
              </option>
            </ng-container>
            <ng-template #noSubCategories>
              <option disabled selected hidden>{{'NO_SUBCATEGORIES' | translate}}</option>
            </ng-template>
          </select>
        </div>

        <div class="form-group trio">
          <label class="form-label" for="product_subsubcategory">{{'SUB_SUBCATEGORY' | translate}}</label>
          <select class="form-select" id="product_subsubcategory" name="subsubcategory" (change)="changeSubSubCategory($event)"
            [disabled]="!subSubCategories || subSubCategories.length === 0">
            <option value="0">{{'SELECT_SUB_SUBCATEGORY' | translate}}</option>
            <ng-container *ngIf="subSubCategories && subSubCategories.length > 0; else noSubSubCategories">
              <option *ngFor="let subSubCategory of subSubCategories" [value]="subSubCategory.subSubCategoryId">
                {{ subSubCategory.subSubCategoryName }}
              </option>
            </ng-container>
            <ng-template #noSubSubCategories>
              <option disabled selected hidden> {{'NO_SUB_SUBCATEGORIES' | translate}}</option>
            </ng-template>
          </select>
        </div>
      </div>


      <div class="form-group-container">
      <div class="form-group couple">
        <label class="form-label is-required" for="product_industryType">{{'INDUSTRY' | translate}}</label>
        <select class="form-select " id="product_industryType" name="industryType" (change)="changeIndustry($event)"
          [disabled]="inputsDisabled">
          <option selected disabled hidden> {{'SELECT_INDUSTRY' | translate}}</option>
          <option *ngFor="let industryType of industryTypes" [value]="industryType.industryTypeId">
            {{ industryType.industryTypeName }}
          </option>
        </select>
      </div>
      <div class="form-group couple">
        <div><label class="form-label" for="product_status"> {{'STATUS' | translate}}</label></div>
        <div class="switch">
          <label class="switch-label">
            <input type="checkbox"id="product_status" name="status" [disabled]="inputsDisabled" [(ngModel)]="isChecked"/>
            <span class="slider round"></span>
          </label>
        </div>
        <div><span id="statusText" class="status-text">{{ isChecked ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</span></div>
      </div>
      </div>

      <div class="form-group-container">
        <div class="form-group couple" *ngIf="true">
          <label class="form-label" for="product_supplier">{{'SUPPLIER'| translate}}</label>
          <select class="form-select" id="product_supplier" name="supplier" (change)="changeSupplier($event)"
            [disabled]="inputsDisabled">
            <option selected disabled hidden> {{'SELECT_SUPPLIER' | translate}}</option>
            <option *ngFor="let supplier of suppliers" [value]="supplier.supplierId">
              {{ supplier.supplierName }}
            </option>
          </select>
        </div>

        <div class="form-group couple" *ngIf="true">
          <label class="form-label" for="product_collection">{{'COLLECTION' | translate}}</label>
          <select class="form-select" id="product_collection" name="collection" (change)="changeCollection($event)"
            [disabled]="inputsDisabled">
            <option selected disabled hidden> {{'SELECT_COLLECTION' | translate}}</option>
            <option *ngFor="let collection of collections" [value]="collection.collectionId">
              {{ collection.collectionName }}
            </option>
          </select>
        </div>
        </div>

      <div class="info-container description">
        <div class="form-label" for="product_description">{{'DESCRIPTION' | translate}}:</div>
        <div class="description-card ql-editor" id="description"></div>
      </div>
      <!-- [customOptions]="customOptions" -->
      <quill-editor class="description-editor" [styles]="{
                  borderRadius: '0 0 10px 10px',
                  padding: '5px',
                  marginBottom: '2vh'
                }" [theme]="'snow'" id="description-edit" [modules]="modules"
        (onEditorChanged)="descriptionChanged($event)"></quill-editor>
    </div>

    <div class="container-price">
      <h1 class="title"> {{'PRICE' | translate}}</h1>

      <div class="form-card">
        <div class="form-group-container top-container">
          <div class="form-group trio">
            <label class="form-label" for="product_cost"> {{'COST_PRICE' | translate}}</label>
            <input type="number" min="0" class="form-control" id="product_cost" name="cost" placeholder="0.00"
              value="{{product.baseCost}}" (input)="changeBaseCost($event)" [disabled]="inputsDisabled" />
          </div>
          <div class="form-group trio">
            <label class="form-label" for="product_gainmargin"> {{'MARGIN' | translate}}</label>
            <input type="number" min="0" class="form-control" id="product_gainmargin" name="gainmargin" placeholder="0%"
              value="{{product.gainMargin}}" (input)="changeGainMargin($event)" [disabled]="inputsDisabled" />
          </div>
          <div class="form-group trio">
            <label class="form-label is-required" for="product_tax"> {{'TAX' | translate}}</label>
            <select class="form-select" id="product_tax" name="tax" (change)="changeTax($event)"
              [disabled]="inputsDisabled" *ngIf="taxActive.taxCode == 1">
              <option value="" disabled selected hidden>{{'SELECT_OPTION' | translate}}</option>
              <option *ngFor="let tax of taxes, let last = last" [value]="tax.taxId" >
                {{ tax.taxName }}
              </option>
            </select>
            <select class="form-select" id="product_tax" name="tax" (change)="changeTax($event)"
              [disabled]="inputsDisabled" *ngIf="taxActive.taxCode == 0">
              <option selected value="taxActive.taxId">{{taxActive.taxName}}</option>
            </select>
            <p id="product_revenue" *ngIf="product.baseCost > 0 && product.sellPrice > 0 && product.taxId > 0">
              {{currency}}{{revenueValue()}}
            </p>
            <p id="product_revenue" *ngIf="product.taxId == 0">
              {{currency}}0
            </p>
          </div>
        </div>
        <hr class="page-separator">
        <div class="form-group-container">
          <div class="form-group trio">
            <label class="form-label is-required" for="product_endprice"> {{'SALE_PRICE' | translate}}</label>
            <input type="number" min="0" class="form-control" id="product_endprice" name="endprice" placeholder="Precio final"
              value="{{product.sellPrice || 0}}" (input)="changeSellPrice($event)" />
          </div>
          <div class="form-group trio">
            <label class="form-label" for="product_sale"> {{'DISCOUNT_PRICE' | translate}}</label>
            <input type="number" min="0" class="form-control" id="product_sale" name="sale" placeholder="0" value="0" (input)="changeDiscountPrice($event)" />
          </div>
          <div class="form-group trio"></div>
        </div>
      </div>

      <div class="container-quantity" id="container-quantity">
        <h1 class="title"> {{'QUANTITY' | translate}}</h1>
        <div class="form-card">
          <div class="form-group-container">
            <div class="form-group couple">
              <label class="form-label" for="product_quantity">{{'BRANCH' | translate}}</label>
              <select class="form-select mb-0" [(ngModel)]="activeNoVariantsShopPoint" (change)="changeNoVariantsShopPoint()">
                <option value="0" selected>{{'ALL' | translate}}</option>
                <option *ngFor="let shopPoint of shopPoints" [value]="shopPoint.shopPointId">{{shopPoint.shopPointName}}</option>
              </select>
            </div>
            <div class="form-group couple">
              <div class="noVariantsQuantities">
                <label class="form-label" for="product_quantity"> {{'QUANTITY_PRODUCT' | translate}}</label>
                <input class="form-control mb-0 no-variant-quantity quantityMain" type="number" min="0" [attr.data-shopPointId]="0" value="0" disabled>
                <input *ngFor="let shopPoint of shopPoints" class="form-control mb-0 visually-hidden no-variant-quantity" type="number" min="0" [attr.data-shopPointId]="shopPoint.shopPointId">
              </div>
            </div>
              <!-- <label class="form-label" for="product_quantity">Cantidad del producto</label>
              <input class="form-control mb-0" type="number" min="0" id="product_quantity"> -->
          </div>
          <small class="form-text text-muted" [innerHTML]="'PRODUCTS_WITHOUT_VARIANTS_NOTE' | translate"></small>
        </div>
      </div>
    </div>

      <div class="container-variables">

        <div class="switch-title-container">
          <h1 class="title"> {{'VARIANTS' | translate}}</h1>
          <label class="form-check-label switch" for="flexCheckDefault">
            <input class="form-check-input" type="checkbox" id="flexCheckDefault" [checked]="areVariablesChecked"
              (change)="variablesHidden($event)">
            <span class="slider round"></span>
          </label>
        </div>

        <div [hidden]="hiddenVar1">
          <div class="form-card">

            <!-- <div class="form-label">Imágenes:</div>
          <div class="images-container">
            <div cdkDropList cdkDropListOrientation="horizontal" class="image-list" (cdkDropListDropped)="drop($event)">
              <div class="image-box" *ngFor="let image of productImages.small; let i = index" [cdkDragDisabled]="false"
                cdkDrag>
                <div class="image-placeholder" *cdkDragPlaceholder></div>
                <img [src]="image.imageURL" />
                <button type="button" class="btn btn-danger delete-button" (click)="deletePhoto(i)">
                  x
                </button>
                <button type="button" class="btn btn-link download-button" (click)="downloadImage(i)">
                  <i class="feather" data-feather="download"></i>
                </button>
              </div>
              <div class="image-box" cdkDragDisabled>
                <button class="add-img" (click)="uploadImage()">+</button>
              </div>
            </div>
          </div> -->

            <div id="formVar1" class="variant-container">
              <div>
                <label class="form-label" for="product_variable_type">
                   {{'TYPE' | translate}}
                </label>
                <input list="product_variable_type1" name="varieble_type" class="form-control"
                  placeholder="{{'ENTER_TYPE' | translate}}" id="variantTypes1" />
                <datalist id="product_variable_type1">
                  <option value="Tamaño">
                  <option value="Color">
                  <option value="Material">
                  <option value="Estilo">
                </datalist>
              </div>
              <div class="value-label">
                <label class="form-label" for="product_variable_value"> {{'VALUE' | translate}}</label>
                <button id="valBtn1" type="button" class="btn btn-outline-secondary  small-btn confirm-btn"
                  (click)="counterVariableInputs1 = addInput('formVar1', 'valBtn1', counterVariableInputs1); showBtn('delVariantBtn', counterVariableInputs1, 'formVar1')">
                  <i class="feather" data-feather="plus"></i>
                </button>
              </div>
            </div>
            <div class="delete-btn-container">
              <button type="button" class="btn btn-outline-secondary cancel-btn" (click)=" varBlock1(); counterVariableInputs1=removeElementsByClass('variantValueInput',
              counterVariableInputs1, 'valBtn1' )">
                {{'DELETE_VARIANT' | translate}}
              </button>
            </div>
          </div>
        </div>

        <div [hidden]="hiddenVar2">
          <div class="form-card">

            <!-- <div class="form-label">Imágenes:</div>
                <div class="images-container">
                  <div cdkDropList cdkDropListOrientation="horizontal" class="image-list" (cdkDropListDropped)="drop($event)">
                    <div class="image-box" *ngFor="let image of productImages.small; let i = index" [cdkDragDisabled]="false"
                      cdkDrag>
                      <div class="image-placeholder" *cdkDragPlaceholder></div>
                      <img [src]="image.imageURL" />
                      <button type="button" class="btn btn-danger delete-button" (click)="deletePhoto(i)">
                        x
                      </button>
                      <button type="button" class="btn btn-link download-button" (click)="downloadImage(i)">
                        <i class="feather" data-feather="download"></i>
                      </button>
                    </div>
                    <div class="image-box" cdkDragDisabled>
                      <button class="add-img" (click)="uploadImage()">+</button>
                    </div>
                  </div>
                </div> -->

            <div id="formVar2" class="variant-container">
              <div>
                <label class="form-label" for="product_variable_type">
                   {{'TYPE' | translate}}
                </label>
                <input list="product_variable_type2" name="varieble_type" class="form-control"
                  placeholder="{{'ENTER_TYPE' | translate}}" id="variantTypes2" />
                <datalist id="product_variable_type2">
                  <option value="Tamaño">
                  <option value="Color">
                  <option value="Material">
                  <option value="Estilo">
                </datalist>
              </div>
              <div class="value-label">
                <label class="form-label" for="product_variable_value"> {{'VALUE' | translate}}</label>
                <button id="valBtn2" type="button" class="btn btn-outline-secondary  small-btn confirm-btn"
                  (click)="counterVariableInputs2 = addInput('formVar2', 'valBtn2', counterVariableInputs2); showBtn('delVariantBtn', counterVariableInputs2, 'formVar2')">
                  <i class="feather" data-feather="plus"></i>
                </button>
              </div>
            </div>
            <div class="delete-btn-container">
              <button type="button" class="btn btn-outline-secondary cancel-btn" (click)=" varBlock2(); counterVariableInputs2=removeElementsByClass('variantValueInput',
                    counterVariableInputs2, 'valBtn2' )">
                 {{'DELETE_VARIANT' | translate}}
              </button>
            </div>

          </div>
        </div>

        <div [hidden]="hiddenVar3">
          <div class="form-card">

            <!-- <div class="form-label">Imágenes:</div>
                <div class="images-container">
                  <div cdkDropList cdkDropListOrientation="horizontal" class="image-list" (cdkDropListDropped)="drop($event)">
                    <div class="image-box" *ngFor="let image of productImages.small; let i = index" [cdkDragDisabled]="false"
                      cdkDrag>
                      <div class="image-placeholder" *cdkDragPlaceholder></div>
                      <img [src]="image.imageURL" />
                      <button type="button" class="btn btn-danger delete-button" (click)="deletePhoto(i)">
                        x
                      </button>
                      <button type="button" class="btn btn-link download-button" (click)="downloadImage(i)">
                        <i class="feather" data-feather="download"></i>
                      </button>
                    </div>
                    <div class="image-box" cdkDragDisabled>
                      <button class="add-img" (click)="uploadImage()">+</button>
                    </div>
                  </div>
                </div> -->

            <div id="formVar3" class="variant-container">
              <div>
                <label class="form-label" for="product_variable_type">
                  {{'TYPE' | translate}}
                </label>
                <input list="product_variable_type3" name="varieble_type" class="form-control"
                  placeholder="{{'ENTER_TYPE' | translate}}" id="variantTypes3" />
                <datalist id="product_variable_type1">
                  <option value="Tamaño">
                  <option value="Color">
                  <option value="Material">
                  <option value="Estilo">
                </datalist>
              </div>
              <div class="value-label">
                <label class="form-label" for="product_variable_value"> {{'VALUE' | translate}}</label>
                <button id="valBtn3" type="button" class="btn btn-outline-secondary  small-btn confirm-btn"
                  (click)="counterVariableInputs3 = addInput('formVar3', 'valBtn3', counterVariableInputs3); showBtn('delVariantBtn', counterVariableInputs3, 'formVar3')">
                  <i class="feather" data-feather="plus"></i>
                </button>
              </div>
            </div>
            <div class="delete-btn-container">
              <button type="button" class="btn btn-outline-secondary cancel-btn" (click)=" varBlock3(); counterVariableInputs3=removeElementsByClass('variantValueInput',
                    counterVariableInputs3, 'valBtn3' )">
                {{'DELETE_VARIANT' | translate}}
              </button>
            </div>

          </div>
        </div>

        <div class="variant-actions-container">
          <button type="button" class="btn btn-outline-secondary confirm-btn" (click)="showVariables()"
            [hidden]="!areVariablesChecked" [disabled]="variableButtonDisabled()">
            {{'ADD_VARIANT' | translate}}
          </button>
          <button type="button" class="btn btn-outline-secondary submit-btn" *ngIf="false"
            (click)="createItemList()">
             {{'CREATE_LIST_VARIANTS' | translate}}
          </button>
        </div>

      </div>

      <div class="form-card" [hidden]="!displayItemsTable">
        <div class="d-flex justify-content-end align-items-center">
          <div>
          <select class="form-select" [(ngModel)]="activeShopPoint" (change)="showShopPoint()">
            <option value="0" selected>{{'ALL_BRANCHS' | translate}}</option>
            <option *ngFor="let shopPoint of shopPoints" [value]="shopPoint.shopPointId">{{shopPoint.shopPointName}}</option>
          </select>
          </div>
        </div>
        <h1 class="variantsTitle" >{{'LIST_VARIANTS' | translate}}</h1>
        <div id="itemCombinationList">
        </div>
      </div>

      <div class="form-buttons">
        <button type="button" class="btn btn-outline-secondary submit-btn" (click)="createProduct()"
          [disabled]="inputsDisabled">
           {{'CREATE' | translate}}
        </button>
      </div>
    </div>
  </div>

  <app-confirmation-modal *ngIf="saveProduct" [title]="'Producto creado con éxito'"
    [confirmText]="'Ir a la lista de productos'" [twoFieldModal]="false" (response)="goBack($event)">
    ></app-confirmation-modal>

    <div class="modal product-modal" id="createProductModal" tabindex="-1">
      <div class="modal-dialog product-modal-dialog" role="document">
        <div class="modal-content product-modal-content">
          <div class="modal-body product-modal-body">
            <div class="loading">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="createValidationModal" tabindex="-1">
      <div class="modal-dialog product-modal-dialog" role="document">
        <div class="modal-content product-modal-content">
          <div class="modal-body product-modal-body">
            <h3 class="text-center"> {{'PRODUCT_NOT_CREATED' | translate}}</h3>
            <p class="text-center">{{'COMPLETE_REQUIRED_FIELDS' | translate}}</p>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-danger cancel-btn" (click)="closeModal()"> {{'CLOSE' | translate}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal error-confirm-modal fade" id="errorCreateModal" tabindex="-1">
      <div class="modal-dialog error-modal-dialog" role="document">
        <div class="modal-content error-modal-content">
          <div class="modal-body error-modal-body">
            <h3 class="text-center"> {{'ERROR' | translate}}</h3>
            <p class="text-center">{{'PRODUCT_CREATION_ERROR' | translate}}</p>
          </div>
          <div class="modal-buttons">
            <button type="button" class="btn btn-confirm" (click)="closeModal()"> {{'CLOSE' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
