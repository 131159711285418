import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as AWS from 'aws-sdk';
import { PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { stringList } from 'aws-sdk/clients/datapipeline';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  [x: string]: any;
  //Base configuration as a string so it can be copied in all the other methods
  // {
  //   credentials: 'same-origin',
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //      'x-api-key': 'hmBGXpVA9Z2GGEpgOFerR398QFgNXvJ31gWRN3bO',
  //   },
  //   method: '',
  //   body: '',
  //   Authorization: ''
  // }

  baseConfig =
    '{"credentials":"same-origin","headers":{"Accept":"application/json","Content-Type":"application/json","x-api-key":"hmBGXpVA9Z2GGEpgOFerR398QFgNXvJ31gWRN3bO"},"method":"","Authorization":""}';

  //Base URL for the API Gateway in AWS, the current stage is added to select the stage in the API Gateway
  baseURL =
    'https://o6yix5dkxl.execute-api.us-east-1.amazonaws.com/' +
    environment.stage;
  //Instance for AWS S3 fiel upload.
  s3Instance: any;

  ID = 'AKIA3ITLEVL46ZWWOSUN';
  SECRET = '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';

  constructor(private injector: Injector) {
    this.createS3Instance();
  }

  //---------------------BASE METHODS--------------------
  // Simple (no authentication required methods)
  async simpleGet(url: string) {
    let config = JSON.parse(this.baseConfig);
    config.method = 'GET';
    return new Promise((resolve, reject) => {
      fetch(this.baseURL + url, config)
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async simplePost(url: string, body: any) {
    let config = JSON.parse(this.baseConfig);
    config.method = 'POST';
    config['body'] = body;
    return new Promise((resolve, reject) => {
      fetch(this.baseURL + url, config)
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async simplePut(url: string, body: any) {
    let config = JSON.parse(this.baseConfig);
    config.method = 'PUT';
    config['body'] = body;
    return new Promise((resolve, reject) => {
      fetch(this.baseURL + url, config)
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // Authorized (authentication required methods)

  async authorizedPut(url: string, body: any) {
    let config = JSON.parse(this.baseConfig);
    config.method = 'PUT';
    config['body'] = body;
    return new Promise((resolve, reject) => {
      fetch(this.baseURL + url, config)
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async authorizedDelete(url: string, body: any) {
    // Change this method !!!!!!!!!!
    let config = JSON.parse(this.baseConfig);
    config.method = 'DELETE';
    config['body'] = body;
    return new Promise((resolve, reject) => {
      fetch(this.baseURL + url, config)
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //-------------------SPECIFIC REQUEST METHODS------------------

  // GET METHODS
  //*FOR PRODUCT*//

  async getThemingService(shopId: number) {
    let url = `/theme?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getShopsByPools(pools: string) {
    let url = `/login?poolId=${pools}`;
    return this.simpleGet(url);
  }

  async getProducts(
    shopId: number,
    counter: number,
    amount: number,
    searchString: string,
    isActive: string,
    creationDateSort: string,
    quantitySort: string
  ) {
    let url = `/products?shopId=${shopId}&counter=${counter}&amount=${amount}&searchString=${searchString}&isActive=${isActive}&creationDateSort=${creationDateSort}&quantitySort=${quantitySort}`;
    return this.simpleGet(url);
  }

  async getProductById(productId: number, shopId: number) {
    return this.simpleGet(`/products/${productId}?shopId=${shopId}`);
  }

  async getProductByCategory(
    categoryId: number,
    shopId: number,
    counter: number,
    amount: number
  ) {
    let url = `/products?categoryId=${categoryId}&shopId=${shopId}&counter=${counter}&amount=${amount}`;
    return this.simpleGet(url);
  }

  async getProductByCollection(
    collectionId: number,
    shopId: number,
    counter: number,
    amount: number
  ) {
    let url = `/products?collectionId=${collectionId}&shopId=${shopId}&counter=${counter}&amount=${amount}`;
    return this.simpleGet(url);
  }

  async getProductByIndustry(
    industryId: number,
    shopId: number,
    counter: number,
    amount: number
  ) {
    let url = `/products?industryId=${industryId}&shopId=${shopId}&counter=${counter}&amount=${amount}`;
    return this.simpleGet(url);
  }

  async getProductByStatus(
    status: number,
    shopId: number,
    counter: number,
    amount: number
  ) {
    let url = `/products?status=${status}&shopId=${shopId}&counter=${counter}&amount=${amount}`;
    return this.simpleGet(url);
  }

  async getProductByTax(
    taxId: number,
    shopId: number,
    counter: number,
    amount: number
  ) {
    let url = `/products?taxId=${taxId}&shopId=${shopId}&counter=${counter}&amount=${amount}`;
    return this.simpleGet(url);
  }

  async getItems(idShop: number) {
    return this.simpleGet('/items');
  }

  //*****************//

  async getClients(
    shopId: number,
    searchString: string,
    counter: number,
    amount: number,
    order: string) {
    let url = `/users?shopId=${shopId}&counter=${counter}&amount=${amount}&searchString=${searchString}&order=${order}`;
    return this.simpleGet(url);
  }

  async getClient(
    shopId: number,
    clientId: number
  ) {
    let url = `/users/${clientId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getClientSales(
    clientId: number,
    counter: number,
    amount: number,
    searchString: string,
    shopId: number) {
    return this.simpleGet(`/users/orders?clientId=${clientId}&counter=${counter}&amount=${amount}&searchString=${searchString}&shopId=${shopId}`);
  }

  async getUserSaleById(saleId: number, shopId: number) {
    return this.simpleGet(`/users/orders/${saleId}?shopId=${shopId}`);
  }

  async getTaxes(idShop: number) {
    let url = `/taxes?shopId=${idShop}`;
    return this.simpleGet(url);
  }

  async getCategories(shopId: number) {
    let url = `/categories?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getSubCategories(shopId: number) {
    let url = `/subCategories?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getSubSubCategories(shopId: number) {
    let url = `/subSubCategories?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getSubCategoryByCategoryId(categoryId: number, shopId: number) {
    let url = `/subCategories/subCategoryList/${categoryId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getSubSubCategoryByCategoryId(subCategoryId: number, shopId: number) {
    let url = `/subSubCategories/subSubCategoryList/${subCategoryId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getCategoryById(idCategory: number, shopId: number) {
    let url = `/categories/${idCategory}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getSubCategoryById(subCategoryId: any, shopId: number) {
    let url = `/subCategories/${subCategoryId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getSubSubCategoryById(subSubCategoryId: any, shopId: number) {
    let url = `/subSubCategories/${subSubCategoryId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getCollectionById(idCollection: number, shopId: number) {
    let url = `/collections/${idCollection}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getSupplierById(idCategory: number, shopId: number) {
    let url = `/suppliers/${idCategory}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getShopPointById(shopPointId: number, shopId: number) {
    let url = `/shoppoints/${shopPointId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getPaymentById(paymentTypeId: number, shopId: number) {
    let url = `/payments/${paymentTypeId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getGiftCardTypesById(giftCardTypeId: number, shopId: number) {
    let url = `/giftCardTypes/${giftCardTypeId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getGiftCardItemsById(giftCardId: number, shopId: number) {
    let url = `/giftCards/${giftCardId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getIndustries() {
    let url = `/industries?`;
    return this.simpleGet(url);
  }

  async getSuppliers(shopId: number) {
    let url = `/suppliers?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getCollections(shopId: number) {
    let url = `/collections?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getShopPoints(shopId: number) {
    let url = `/shoppoints?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getPaymentMethods(shopId: number) {
    let url = `/payments?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getPaymentMethodById(shopId: number, paymentId: number) {
    let url = `/payments/${paymentId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getItemImage(code: string) {
    return this.simpleGet('/items/image?code=' + code);
  }

  async getCoupons(
    shopId: number,
    counter: number,
    amount: number,
    searchString: string
  ) {
    let url = `/coupons?shopId=${shopId}&counter=${counter}&amount=${amount}&searchString=${searchString}`;
    return this.simpleGet(url);
  }

  async getCoupon(idCoupon: number, shopId: number) {
    let url = `/coupons/${idCoupon}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getReview(idReview: number, idShop: number) {
    let url = `/reviews/idReview?idReview=${idReview}&idShop=${idShop}`;
    return this.simpleGet(url);
  }

  async getSales(
    shopId: number,
    searchString: string,
    counter: number,
    amount: number,
    startDate: string,
    endDate: string,
    paymentTypeId: string,
    saleState: number | null
  ) {
    let url = `/sales?shopId=${shopId}&searchString=${searchString}&counter=${counter}&amount=${amount}&startDate=${startDate}&endDate=${endDate}&paymentTypeId=${paymentTypeId}&saleState=${saleState}`;
    return this.simpleGet(url);
  }

  async getSale(saleId: number, shopId: number) {
    return this.simpleGet(`/sales/${saleId}?shopId=${shopId}`);
  }

  async getDailySales(
    shopId: number,
    channel: number,
    startDate: string,
    endDate: string
  ) {
    let url = `/sales/daily?shopId=${shopId}&channel=${channel}&startDate=${startDate}&endDate=${endDate}`;
    return this.simpleGet(url);
  }

  getShopData(shopId: number) {
    let url = `/shop?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  getCountries() {
    let url = `/shop/location?geoLocationType=0&geoLocationId=0`;
    return this.simpleGet(url);
  }

  getGeoLocation(geoLocationType: number, geoLocationId: number) {
    let url = `/shop/location?geoLocationType=${geoLocationType}&geoLocationId=${geoLocationId}`;
    return this.simpleGet(url);
  }

  async updateInventory(shopId: number) {
    return this.simpleGet(`/integrations/updateinventory?shopId=${shopId}&images=0`);
  }

  async updateImages(shopId: number) {
    return this.simpleGet(`/integrations/updateinventory?shopId=${shopId}&images=1`);
  }

  async getBanners(shopId: number) {
    return this.simpleGet(`/theme/banners?shopId=${shopId}`);
  }

  async getGiftCardTypes(shopId: number) {
    let url = `/giftCardTypes?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getGiftCardItems(shopId: number) {
    let url = `/giftCards?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async exportItems(shopId: number, itemCodes: string = "", startDate: string = "", endDate: string = "") {
    let url = `/products/export?shopId=${shopId}&itemCodes=${itemCodes}&startDate=${startDate}&endDate=${endDate}`;
    return this.simpleGet(url);
  }

  async getCashRegisters(shopId: number) {
    let url = `/cashRegister?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getCashRegisterById(cashRegisterId: number, shopId: number) {
    let url = `/cashRegister/${cashRegisterId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }



  // POST METHODS

  // createProduct(
  //   productCode: string,
  //   shopId: number,
  //   productCategoryId: number,
  //   productIndustryId: number,
  //   productSupplierId: number | null,
  //   productCollectionId: number | null,
  //   productTaxId: number,
  //   productName: string,
  //   productDescription: string,
  //   productBaseCost: number,
  //   productSellPrice: number,
  //   productDiscountPrice: number,
  //   productCreationDate: any,
  //   productStatus: number,
  //   itemCode: string,
  //   itemActive: number,
  //   itemPrice: number,
  //   imgURL: string,
  //   imgPosition: number,
  //   imgType: string
  // ) {
  //   let url = `/products/create?productCode=${productCode}&shopId=${shopId}&productCategoryId=${productCategoryId}&productIndustryId=${productIndustryId}&productSupplierId=${productSupplierId}&productCollectionId=${productCollectionId}&productTaxId=${productTaxId}&productName=${productName}&productDescription=${productDescription}&productBaseCost=${productBaseCost}&productSellPrice=${productSellPrice}&productDiscountPrice=${productDiscountPrice}&productCreationDate=${productCreationDate}&productStatus=${productStatus}&itemCode=${itemCode}&itemActive=${itemActive}&itemPrice=${itemPrice}&imgURL=${imgURL}&imgPosition=${imgPosition}&imgType=${imgType}`;
  //   let body = {
  //     productCode,
  //     shopId,
  //     productCategoryId,
  //     productIndustryId,
  //     productSupplierId,
  //     productCollectionId,
  //     productTaxId,
  //     productName,
  //     productDescription,
  //     productBaseCost,
  //     productSellPrice,
  //     productDiscountPrice,
  //     productCreationDate,
  //     productStatus,
  //     itemCode,
  //     itemActive,
  //     itemPrice,
  //     imgURL,
  //     imgPosition,
  //     imgType,
  //   };
  //   return this.simplePost(url, JSON.stringify(body));
  // } [DEPRECATED]

  createCoupon(
    couponId: number,
    shopId: number,
    couponSaleId: number | null,
    couponProductId: number | null,
    couponCategoryId: number | null,
    couponSubCategoryId: number | null,
    couponCode: string | null,
    couponType: string | null,
    couponPriceValue: number,
    couponPercentageValue: number,
    couponMinArticlesQuantity: number | null,
    couponMaxUses: number | null,
    couponDateStart: any,
    couponDateEnd: any,
    couponMinimunCost: number | null,
    couponMaximunDiscount: number,
    couponActive: number
  ) {
    let url = `/coupons/create?couponId=${couponId}&shopId=${shopId}&couponSaleId=${couponSaleId}&couponProductId=${couponProductId}&couponCategoryId=${couponCategoryId}&couponSubCategoryId=${couponSubCategoryId}&couponCode=${couponCode}&couponType=${couponType}&couponPriceValue=${couponPriceValue}&couponPercentageValue=${couponPercentageValue}&couponMinArticlesQuantity=${couponMinArticlesQuantity}&couponMaxUses=${couponMaxUses}&couponDateStart=${couponDateStart}&couponDateEnd=${couponDateEnd}&couponMinimunCost=${couponMinimunCost}&couponMaximunDiscount=${couponMaximunDiscount}&couponActive=${couponActive}`;
    let body = {
      couponId,
      shopId,
      couponSaleId,
      couponProductId,
      couponCategoryId,
      couponSubCategoryId,
      couponCode,
      couponType,
      couponPriceValue,
      couponPercentageValue,
      couponMinArticlesQuantity,
      couponMaxUses,
      couponDateStart,
      couponDateEnd,
      couponMinimunCost,
      couponMaximunDiscount,
      couponActive,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  createCategory(
    categoryIdParent: number,
    shopId: number,
    categoryName: string,
    categoryActiveIcon: string,
    categoryNonActiveIcon: string,
    categoryPosition: number,
    menuCategory: number,
    homeCategory: number,
    categoryDesignType: number,
    categoryImageUrl: string,
  ) {
    let url = `/categories/create`;
    let body = {
      categoryIdParent,
      shopId,
      categoryName,
      categoryActiveIcon,
      categoryNonActiveIcon,
      categoryPosition,
      menuCategory,
      homeCategory,
      categoryDesignType,
      categoryImageUrl
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  createSubCategory(
    shopId: number,
    subCategoryName: string,
    subCategoryPosition: number,
    menuSubCategory: number,
    homeSubCategory: number,
    categoryId: number,
    subCategoryDesignType: number,
    subCategoryImageUrl: string
  ) {
    let url = `/subCategories/create`;
    let body = {
      shopId,
      subCategoryName,
      subCategoryPosition,
      menuSubCategory,
      homeSubCategory,
      categoryId,
      subCategoryDesignType,
      subCategoryImageUrl
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  createSubSubCategory(
    shopId: number,
    subSubCategoryName: string,
    subSubCategoryPosition: number,
    menuSubSubCategory: number,
    homeSubSubCategory: number,
    subCategoryId: number,
    subSubCategoryDesignType: number,
    subSubCategoryImageUrl: string
  ) {
    let url = `/subSubCategories/create`;
    let body = {
      shopId,
      subSubCategoryName,
      subSubCategoryPosition,
      menuSubSubCategory,
      homeSubSubCategory,
      subCategoryId,
      subSubCategoryDesignType,
      subSubCategoryImageUrl
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  /* COLLECTIONS */
  createCollection(
    collectionName: string,
    collectionDescription: string,
    shopId: number,
  ) {
    let url = `/collections/create`;
    let body = {
      collectionName,
      collectionDescription,
      shopId,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  updateCollection(
    collectionId: number,
    collectionName: string,
    shopId: number,
    collectionDescription: string,
  ) {
    let url = `/collections/${collectionId}`;
    let body = {
      collectionId,
      collectionName,
      shopId,
      collectionDescription,
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));
  }

  deleteCollection(shopId: number, collectionId: number) {
    let url = '/collections/delete';
    let body = {
      shopId,
      collectionId,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  /* SUPPLIERS */
  createSupplier(
    supplierName: string,
    supplierDescription: string,
    shopId: number,
  ) {
    let url = `/suppliers/create`;
    let body = {
      supplierName,
      supplierDescription,
      shopId,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  updateSupplier(
    supplierId: number,
    supplierName: string,
    shopId: number,
    supplierDescription: string,

  ) {
    let url = `/suppliers/${supplierId}`;
    let body = {
      supplierId,
      supplierName,
      shopId,
      supplierDescription,
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));
  }

  deleteSupplier(shopId: number, supplierId: number) {
    let url = '/suppliers/delete';
    let body = {
      shopId,
      supplierId,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  // SHOPPOINTS

  createShopPoint(
    shopId: number,
    shopPointName: string,
    shopPointPhone: string,
    shopPointLocation: string,
    shopPointStatus: number,
  ) {
    let url = `/shoppoints/create`;
    let body = {
      shopId,
      shopPointName,
      shopPointPhone,
      shopPointLocation,
      shopPointStatus
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  updateShopPoint(
    shopId: number,
    shopPointId: number,
    shopPointName: string,
    shopPointLocation: string,
    shopPointPhone: string,
    shopPointStatus: number
  ) {
    let url = `/shoppoints/${shopPointId}`;
    let body = {
      shopId,
      shopPointId,
      shopPointName,
      shopPointLocation,
      shopPointPhone,
      shopPointStatus
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));
  }

  deleteShopPoint(shopId: number, shopPointId: number) {
    let url = '/shoppoints/delete';
    let body = {
      shopId,
      shopPointId,
    };
    console.log("body", body);
    return this.simplePost(url, JSON.stringify(body));
  }

  /* PAYMENT METHODS */

  createPaymentMethod(
    shopId: number,
    paymentTypeId: number,
    config: string,
    paymentDescription: string,
    paymentStatus: number
  ) {
    let url = `/payments/create`;
    let body = {
      shopId,
      paymentTypeId,
      config,
      paymentDescription,
      paymentStatus
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  updatePaymentMethod(
    shopId: number,
    paymentTypeId: number,
    config: string,
    paymentDescription: string,
    paymentStatus: number = 1
  ) {
    let url = `/payments/${paymentTypeId}`;
    let body = {
      shopId,
      config,
      paymentDescription,
      paymentStatus
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));

  }
  deletePayment(shopId: number, paymentTypeId: number) {
    let url = '/payments/delete';
    let body = {
      shopId,
      paymentTypeId,
    };
    console.log("body", body);
    return this.simplePost(url, JSON.stringify(body));
  }

  /* TIMEKIT */
  async getAvailability(shopId: number, projectId: string) {
    let url = `/timekit/availability?shopId=${shopId}&projectId=${projectId}`;
    return this.simpleGet(url);
  }

  async getBookings(shopId: number, projectId: string) {
    let url = `/timekit/bookings?shopId=${shopId}&projectId=${projectId}`;
    let body = {
      shopId,
      projectId,
      action: "bookings"
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  async rescheduleBookings(shopId: number, projectId: string, bookingId: string, start: string, end: string) {
    let url = `/timekit/bookings?shopId=${shopId}&projectId=${projectId}&bookingId=${bookingId}`;
    let body = {
      bookingId,
      start: start,
      end: end,
      action: 'reschedule'
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  cancelBooking(shopId: number, projectId: string, bookingId: string) {
    let url = `/timekit/bookings?shopId=${shopId}&projectId=${projectId}`;
    let body = {
      bookingId,
      action: 'cancelBooking'
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));
  }

  updateAvailability(shopId: number, projectId: string, formattedConstraints: any) {
    let url = `/timekit/schedule?shopId=${shopId}&projectId=${projectId}`;
    let body = {
      action: 'updateAvailability',
      availabilityConstraints: formattedConstraints
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));
  }

  createShopOffice(idShop: number, shopOffice: string, idShopPoint: number) {
    let url = '/settings/branchoffice';
    let body = {
      idShop,
      shopOffice,
      idShopPoint,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  updateShopInfo(
    shopId: number,
    shopName: string,
    shopEmail: string,
    shopCurrencyId: number
  ) {
    let url = `/shop/info`;
    let body = {
      shopId,
      shopName,
      shopEmail,
      shopCurrencyId
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));
  }

  updateShopSocialMedia(
    shopId: number,
    socialMediaData: string
  ) {
    let url = `/shop/socialmedia`;
    let body = {
      shopId,
      socialMediaData
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));
  }

  updateShopLocation(
    shopId: number,
    countryId: number,
    shopGeoLocation1Id: number,
    shopGeoLocation2Id: number,
    shopGeoLocation3Id: number
  ) {
    let url = `/shop/location`;
    let body = {
      shopId,
      countryId,
      shopGeoLocation1Id,
      shopGeoLocation2Id,
      shopGeoLocation3Id
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));
  }

  updateCategory(
    categoryId: number,
    categoryIdParent: number,
    shopId: number,
    categoryName: string,
    categoryActiveIcon: string,
    categoryNonActiveIcon: string,
    categoryPosition: number,
    menuCategory: number,
    homeCategory: number,
    categoryDesignType: number,
    categoryImageUrl: string,
  ) {
    let url = `/categories/${categoryId}`;
    let body = {
      categoryId,
      categoryIdParent,
      shopId,
      categoryName,
      categoryActiveIcon,
      categoryNonActiveIcon,
      categoryPosition,
      menuCategory,
      homeCategory,
      categoryDesignType,
      categoryImageUrl
    };
    return this.simplePut(url, JSON.stringify(body));
  }

  deleteCategory(idShop: number, idCategory: number) {
    let url = '/categories/delete';
    let body = {
      idShop,
      idCategory,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  updateSubCategory(
    subCategoryId: number,
    shopId: number,
    subCategoryName: string,
    subCategoryPosition: number,
    menuSubCategory: number,
    homeSubCategory: number,
    categoryId: number,
    subCategoryDesignType: number,
    subCategoryImageUrl: string,
  ) {
    let url = `/subCategories/${subCategoryId}`;
    let body = {
      subCategoryId,
      shopId,
      subCategoryName,
      subCategoryPosition,
      menuSubCategory,
      homeSubCategory,
      categoryId,
      subCategoryDesignType,
      subCategoryImageUrl
    };
    return this.simplePut(url, JSON.stringify(body));
  }

  updateSubSubCategory(
    subSubCategoryId: number,
    shopId: number,
    subSubCategoryName: string,
    subSubCategoryPosition: number,
    menuSubSubCategory: number,
    homeSubSubCategory: number,
    subCategoryId: number,
    subSubCategoryDesignType: number,
    subSubCategoryImageUrl: string,
  ) {
    let url = `/subSubCategories/${subSubCategoryId}`;
    let body = {
      subSubCategoryId,
      shopId,
      subSubCategoryName,
      subSubCategoryPosition,
      menuSubSubCategory,
      homeSubSubCategory,
      subCategoryId,
      subSubCategoryDesignType,
      subSubCategoryImageUrl
    };
    return this.simplePut(url, JSON.stringify(body));
  }

  deleteSubCategory(idShop: number, subCategoryId: number) {
    let url = '/subCategories/delete';
    let body = {
      idShop,
      subCategoryId,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  deleteSubSubCategory(idShop: number, subSubCategoryId: number) {
    let url = '/subSubCategories/delete';
    let body = {
      idShop,
      subSubCategoryId,
    };
    return this.simplePost(url, JSON.stringify(body));
  }
  // Gift Cards
  createGiftCardItem(
    giftCardTypeId: number,
    shopId: number,
    amount: number,
    status: number,
    giftCardEmail: string,
    giftCardMessage: string,
    giftCardIsVirtual: number,
  ) {
    let url = `/giftCards/create`;
    let body = {
      giftCardTypeId,
      shopId,
      amount,
      status,
      giftCardEmail,
      giftCardMessage,
      giftCardIsVirtual,
    };

    return this.simplePost(url, JSON.stringify(body))
      .then((result: any) => {
        console.log("Api Json", result);
        return result;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  createGiftCardType(
    name: string,
    validMonths: number,
    shopId: number,
    amount: string,
    stock: number,
    status: number,
  ) {
    let url = `/giftCardTypes`;
    let body = {
      name,
      validMonths,
      shopId,
      amount,
      stock,
      status,
    };
    console.log(body);
    return this.simplePost(url, JSON.stringify(body));
  }

  updateGiftCard(
    giftCardTypeId: number,
    name: string,
    shopId: number,
    amount: string,
    stock: number,
    status: number,
    validMonths: number,
    images: string,
  ) {
    let url = `/giftCardTypes/${giftCardTypeId}`;
    let body = {
      giftCardTypeId,
      name,
      shopId,
      amount,
      stock,
      status,
      validMonths,
      images,
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));

  }

  deleteGiftCardType(shopId: number, giftCardTypeId: number) {
    let url = '/giftCardTypes/delete';
    let body = {
      shopId,
      giftCardTypeId,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  async getComponents(shopId: number) {
    let url = `/components?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getComponentTypes(shopId: number) {
    let url = `/components/componentType?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getComponentById(idComponent: number, shopId: number) {
    let url = `/components/${idComponent}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  async getMenu(shopId: number) {
    let url = `/menu?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  createComponent(
    componentId: number,
    shopId: number,
    componentName: string,
    title: string,
    subtitle: string,
    position: number,
    status: number,
    description: string,
    componentTypeId: number,
    itemQuantity: number,
    itemListId: number,
    itemSort: number,
    infoImageUrl: string,
    infoDescription: any,
    redirectUrl: string,
    infoTitles: string,
    urlNames: string,
    collectionId: number,
    categoryId: number,
    subCategoryId: number,
    subSubCategoryId: number,
    productComponentType: number,

  ): Promise<any> {
    let url = `/components/create`;
    let body = {
      componentId,
      shopId,
      componentName,
      title,
      subtitle,
      position,
      status,
      description,
      componentTypeId,
      itemQuantity,
      itemListId,
      itemSort,
      infoImageUrl,
      infoDescription,
      redirectUrl,
      infoTitles,
      urlNames,
      collectionId,
      categoryId,
      subCategoryId,
      subSubCategoryId,
      productComponentType
    };
    console.log("Creacion de componente",body);
    return this.simplePost(url, JSON.stringify(body));
  }

  updateComponentStatusAndPosition(componentDataString: string, shopId: number) {
    let url = `/components?shopId=${shopId}`;
    const requestData = {
      componentData: componentDataString,
      shopId: shopId
    };
    return this.simplePut(url, JSON.stringify(requestData));
  }

  updateComponent(
    componentId: number,
    shopId: number,
    title: string,
    subtitle: string,
    description: string,
    itemQuantity: number,
    itemListId: number,
    itemSort: number,
    infoImageUrl: string,
    infoDescription: string,
    redirectUrl: string,
    infoIds: string,
    deleteInfoIds: string,
    infoTitles: string,
    urlNames: string,
    collectionId: number,
    categoryId: number,
    subCategoryId: number,
    subSubCategoryId: number,
    productComponentType: number,
    componentTypeId: number,
    componentName: string
  ) {
    let url = `/components/${componentId}`;
    const body = {
      componentId,
      shopId,
      title,
      subtitle,
      description,
      itemQuantity,
      itemListId,
      itemSort,
      infoImageUrl,
      infoDescription,
      redirectUrl,
      infoIds,
      deleteInfoIds,
      infoTitles,
      urlNames,
      collectionId,
      categoryId,
      subCategoryId,
      subSubCategoryId,
      productComponentType,
      componentTypeId,
      componentName
    };
    return this.simplePut(url, JSON.stringify(body));
  }

  deleteComponent(shopId: number, componentId: number) {
    let url = '/components/delete';
    let body = {
      shopId,
      componentId,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  updateMenuStatus(status: number, menuId: number, shopId: number) {
    let url = `/menu?shopId=${shopId}`;

    const requestData = {
      shopId: shopId,
      menuId: menuId,
      status: status,
    };
    return this.simplePut(url, JSON.stringify(requestData));
  }

  updateMenuOptionsPositions(updateData: { shopId: number, menuId: number, positions: { [key: number]: number } }) {
    let url = `/menu?shopId=${updateData.shopId}`;
    return this.simplePost(url, JSON.stringify(updateData));
  }

  createMenu(
    shopId: number,
    menuId: number,
    menuName: string,
    menuStatus: number,
    menuOptions: any[]
  ) {
    let url = `/menu/create`;
    let body = {
      shopId,
      menuId,
      menuName,
      menuStatus,
      menuOptions
    };
    console.log("createMenu", body)
    return this.simplePost(url, JSON.stringify(body));
  }

  updateMenu(
    shopId: number,
    menuId: number,
    menuName: string,
    menuStatus: number,
    menuOptions: any[],
    deletedMenuOptionIds: any,
  ) {

    let url = `/menu/${menuId}`;

    const body = {
      menuId,
      shopId,
      menuName,
      menuStatus,
      menuOptions,
      deletedMenuOptionIds,

    };
    return this.simplePut(url, JSON.stringify(body));
  }

  async getMenuById(menuId: number, shopId: number) {
    let url = `/menu/${menuId}?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  deleteMenu(idShop: number, idMenu: number) {
    let url = '/menu/delete';
    let body = {
      idShop,
      idMenu,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  createTaxes(
    idTaxes: number,
    idShop: number,
    codeIVA: number,
    nameIVA: string,
    descriptionIVA: string
  ) {
    let url = '/settings/taxes';
    let body = {
      idTaxes,
      idShop,
      codeIVA,
      nameIVA,
      descriptionIVA,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  deleteTaxes(idTaxes: number, idShop: number) {
    let url = '/settings/taxes/delete';
    let body = {
      idTaxes,
      idShop,
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  //images
  createItemImages(payload: any) {
    let url = '/products/images'
    return this.simplePost(url, JSON.stringify(payload))
  }

  // PUT METHODS

  updateThemingColors(
    shopId: number,
    shopMainColor: string,
    shopSecondaryColor: string,
    shopHeaderColor: string,
    shopMainColorDark: string,
  ) {
    let url = '/theme/color';
    let body = {
      shopId,
      shopMainColor,
      shopSecondaryColor,
      shopHeaderColor,
      shopMainColorDark
    }
    return this.simplePut(url, JSON.stringify(body));
  }

  updateThemingLogo(shopId: number, mainLogo: string, secondaryLogo: string, mobileLogo: string) {
    let url = '/theme/logo';
    let body = {
      shopId,
      mainLogo,
      secondaryLogo,
      mobileLogo
    }
    return this.simplePut(url, JSON.stringify(body));
  }

  updateThemingBanners(shopId: number, banners: any[]) {
    let url = '/theme/banners';
    let body = {
      shopId: shopId,
      banners: banners
    }
    return this.simplePut(url, JSON.stringify(body));
  }

  updateTax(shopId: number, taxId: number) {
    let url = '/taxes';
    let body = {
      shopId,
      taxId
    }
    return this.simplePut(url, JSON.stringify(body));
  }

  updateItemsImages(itemIds: any[]) {
    let url = '/items/image';
    let body = {
      id_items: itemIds,
    };
    return this.authorizedPut(url, JSON.stringify(body));
  }

  updateProduct(
    productId: number,
    shopId: number,
    description: string,
    active: any,
    items: any[],
    name: string,
    categoryId: number,
    subCategoryId: number,
    subSubCategoryId: number,
    industryId: number,
    supplierId: number | any,
    collectionId: number | any,
    baseCost: number,
    taxId: number,
    sellPrice: number,
    discountPrice: number,
    variants: any[],
    productCreationDate: any,
    deleteItems: any[],
  ) {

    baseCost = parseFloat(baseCost.toString());
    sellPrice = parseFloat(sellPrice.toString());
    discountPrice = parseFloat(discountPrice.toString());

    let url = `/products/${productId}`;
    let body = {
      shopId,
      active,
      description,
      name,
      categoryId,
      subCategoryId,
      subSubCategoryId,
      industryId,
      collectionId,
      supplierId,
      baseCost,
      sellPrice,
      discountPrice,
      taxId,
      items,
      variants,
      deleteItems,
      productCreationDate,
    };
    console.log(JSON.stringify(body));
    return this.simplePut(url, JSON.stringify(body));
  }

  createProduct(
    productCode: string,
    shopId: number,
    productCategoryId: number,
    productSubCategoryId: number,
    productSubSubCategoryId: number,
    productIndustryId: number,
    productSupplierId: number | any,
    productCollectionId: number | any,
    productTaxId: number,
    productName: string,
    productDescription: string,
    productBaseCost: number,
    productSellPrice: number,
    productDiscountPrice: number,
    productCreationDate: any,
    productStatus: number,
    items: any[],
    variants: any[],
    imgURL: string,
    imgPosition: number,
    imgType: string
  ) {
    let url = '/products/create';

    let body = {
      productCode,
      shopId,
      productCategoryId,
      productSubCategoryId,
      productSubSubCategoryId,
      productIndustryId,
      productSupplierId,
      productCollectionId,
      productTaxId,
      productName,
      productDescription,
      productBaseCost,
      productSellPrice,
      productDiscountPrice,
      productCreationDate,
      productStatus,
      items,
      variants,
      imgURL,
      imgPosition,
      imgType
    };

    return this.simplePost(url, JSON.stringify(body));
  }

  uploadProducts(
    data: object
  ) {
    let url = '/products/upload';
    let body = data;
    return this.simplePost(url, JSON.stringify(body));
  }

  updateProductsStatus(shopId: number, productsIdList: string, productsStatus: number){
    let url = `/products/update/status`;
    let body = {
      productsIdList,
      shopId,
      productsStatus
    }
    return this.simplePost(url, JSON.stringify(body));
  }

  updateCoupon(
    couponId: number,
    shopId: number,
    couponSaleId: number | null,
    couponProductId: number | null,
    couponCategoryId: number | null,
    couponSubCategoryId: number | null,
    couponCode: string | null,
    couponType: string | null,
    couponPriceValue: number,
    couponPercentageValue: number,
    couponMinArticlesQuantity: number | null,
    couponMaxUses: number | null,
    couponDateStart: any,
    couponDateEnd: any,
    couponMinimunCost: number | null,
    couponMaximunDiscount: number,
    couponActive: number
  ) {
    let url = `/coupons/${couponId}?shopId=${shopId}&couponSaleId=${couponSaleId}&couponProductId=${couponProductId}&couponCategoryId=${couponCategoryId}&couponSubCategoryId=${couponSubCategoryId}&couponCode=${couponCode}&couponType=${couponType}&couponPriceValue=${couponPriceValue}&couponPercentageValue=${couponPercentageValue}&couponMinArticlesQuantity=${couponMinArticlesQuantity}&couponMaxUses=${couponMaxUses}&couponDateStart=${couponDateStart}&couponDateEnd=${couponDateEnd}&couponMinimunCost=${couponMinimunCost}&couponMaximunDiscount=${couponMaximunDiscount}&couponActive=${couponActive}`;
    let body = {
      couponId,
      shopId,
      couponSaleId,
      couponProductId,
      couponCategoryId,
      couponSubCategoryId,
      couponCode,
      couponType,
      couponPriceValue,
      couponPercentageValue,
      couponMinArticlesQuantity,
      couponMaxUses,
      couponDateStart,
      couponDateEnd,
      couponMinimunCost,
      couponMaximunDiscount,
      couponActive,
    };
    return this.simplePut(url, JSON.stringify(body));
  }

  createClient(
    shopId: number,
    firstName: string,
    lastName: string,
    phone: number,
    nationalId: number,
    email: string,
    username: string,
    isPoolRegister: boolean,
    password: any
  ) {
    let url = `/users/register`;
    let body = {
      shopId,
      firstName,
      lastName,
      phone,
      nationalId,
      email,
      username,
      isPoolRegister,
      password
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  updateClient(
    shopId: any,
    clientId: any,
    username: string,
    firstName: string,
    lastName: string,
    email:string,
    phone: number,
    nationalId: number,
    isPoolRegister: boolean,
    gender: string,
    password: any,
    access: any
  ){
    let url = `/users/${clientId}`;
    let body = {
      shopId: shopId,
      clientId: clientId,
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      nationalId: nationalId,
      isPoolRegister: isPoolRegister,
      gender: gender,
      password: password,
      access: access
    }
    return this.simplePut(url, JSON.stringify(body));
  }

  deleteClient(shopId: number, clientId: number, username: any ) {
    let url = '/users/delete';
    let body = {
      shopId,
      clientId,
      username
    };
    return this.simplePost(url, JSON.stringify(body));
  }
  // updateBannersImages(banners: any[], idShop: number) {
  //   let url = `/banners?idShop=${idShop}`;
  //   let body = {
  //     banners,
  //     idShop,
  //   };
  //   return this.authorizedPut(url, JSON.stringify(body));
  // }

  // deleteBanner(banners: any[], idShop: number) {
  //   let url = `/banners/deletebanner?idShop=${idShop}`;
  //   let body = {
  //     banners,
  //     idShop,
  //   };
  //   return this.authorizedPut(url, JSON.stringify(body));
  // }

  // saveProductChanges(idProduct: number, description: string) {
  //   let url = `/products/${idProduct}/savechanges`;
  //   let body = {
  //     description: description,
  //   };
  //   return this.authorizedPut(url, JSON.stringify(body));
  // }

  // saveStatusChange(idSale: number, state: string) {
  //   let url = `/sales/${idSale}/changestate`;
  //   let body = {
  //     state,
  //   };
  //   return this.authorizedPut(url, JSON.stringify(body));
  // }

  async changeSaleState(
    saleId: number,
    shopId: number,
    saleState: number,
    saleOrderCode: string
  ) {
    let body = {
      shopId: shopId,
      saleState: saleState,
      saleOrderCode: saleOrderCode,
    };
    return this.simplePut(`/sales/${saleId}`, JSON.stringify(body));
  }

  async sendGiftCardEmail(requestBody: { shopId: number; giftCards: { giftCardId: any; }[]; }) {
    return this.simplePost(`/giftCards/sendEmail`, JSON.stringify(requestBody));
  }
  //DELETE METHODS

  deleteItemImages(images: any[]) {
    let url = '/products/images/delete';
    let body = images
    return this.simplePost(url, JSON.stringify(body));
  }

  //S3

  createS3Instance() {
    this.s3Instance = new AWS.S3({
      accessKeyId: this.ID,
      secretAccessKey: this.SECRET,
      region: 'us-east-1',
    });
  }

  //Upload file to S3.
  async uploadFile(url: string, file: File, options: any) {
    const BUCKET_NAME = 'mobydyg-files';
    const params = {
      Bucket: BUCKET_NAME,
      Key: url, // File name you want to save as in S3
      Body: file,
    };
    return new Promise((resolve, reject) => {
      this.s3Instance.upload(params, function (err: any, data: any) {
        console.log(err)
        if (err) {
          throw err;
        } else {
          console.log(`File uploaded successfully. ${data.Location}`);
          resolve({ imageURL: data.Location, options: options });
        }
      });
    });
  }

  uploadHtmlContent(url: string, htmlContent: string, options: any) {
    const BUCKET_NAME = 'mobydyg-files';
    const params = {
      Bucket: BUCKET_NAME,
      Key: url,
      Body: htmlContent,
      ContentType: 'text/html; charset=utf-8'
    };

    return new Promise((resolve, reject) => {
      this.s3Instance.upload(params, function (err: any, data: any) {
        if (err) {
          reject(err);
        } else {
          console.log(`Upload success. Location: ${data.Location}`);
          resolve(data.Location);
        }
      });
    });
  }

  //Delete file from S3.
  async deleteFile(url: string) {
    const BUCKET_NAME = 'mobydyg-files';
    const params = {
      Bucket: BUCKET_NAME,
      Key: url,
    };
    return new Promise((resolve, reject) => {
      this.s3Instance.deleteObject(params, function (err: any, data: any) {
        if (err) {
          reject(err);
        } else {
          console.log(`File deleted successfully from S3: ${url}`);
          resolve(data);
        }
      });
    });
  }


  async uploadFileWithPreSignedURL(file: File) {
    const contentType = file.type;

    const params = {
      Bucket: 'yourbucketname',
      Key: file.name,
      ACL: 'public-read',
      ContentType: contentType,
    };

    const command = new PutObjectCommand(params);

    // try {
    //   const preSignedURL = await getSignedUrl(this.bucket, command, { expiresIn: 3600});

    //   console.log(preSignedURL);
    //   this.http.put(preSignedURL, file).subscribe({
    //     next: (res) => {
    //       console.log("SUCCESS", res);
    //     },
    //     error: (err) => {
    //       console.log("FAILED", err);
    //     },
    //     complete: () => {
    //       console.log("DONE")
    //     }
    //   })
    // } catch(err) {
    //   console.log(err);
    // }
  }

  async currentAuthUser() {
    const auth = this.injector.get(AuthService);

    let user;
    await auth.currentAuthUser().then((data) => {
      user = data;
    });

    console.log(user);

    return user;
  }
}
