import { Component, ElementRef, Input } from '@angular/core';
import { ITimekitComponent  } from '../timekit-tabs/timekit-tabs.component';


@Component({
  selector: 'app-timekit-follow-up',
  templateUrl: './timekit-follow-up.component.html',
  styleUrls: ['./timekit-follow-up.component.scss']
})


export class TimekitFollowUpComponent implements ITimekitComponent {
  @Input() data: any;
  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    const style = document.createElement('style');
    document.head.appendChild(style);

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdn.timekit.io/booking-js/v3/booking.min.css';
    document.head.appendChild(link);

    const config: any = {
      app_key: 'live_api_key_ozl1I0U8YlxlmwskC7qKBHaBYSAWMSa6',
      project_id: '311b8064-7b8c-4df0-8786-9933e6698007',
      fullcalendar: {
        headerToolbar: {
          left: '',
          center: '',
          right: 'today, prev, next',
          locale: 'es',
        },
      }
    };

    (window as any).timekitBookingConfig = config;


    const script = document.createElement('script');
    script.src = 'https://cdn.timekit.io/booking-js/v3/booking.min.js';
    script.defer = true;
    document.body.appendChild(script);
  }

  ngOnDestroy(): void {
    const style = document.querySelector('style');
    if (style) document.head.removeChild(style);

    const link = document.querySelector('link[href="https://cdn.timekit.io/booking-js/v3/booking.min.css"]');
    if (link) document.head.removeChild(link);

    const script = document.querySelector('script[src="https://cdn.timekit.io/booking-js/v3/booking.min.js"]');
    if (script) document.body.removeChild(script);
  }
}





