import { Component, ComponentFactoryResolver, Injector, AfterViewInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { TimekitRescheduleComponent } from '../timekit-reschedule/timekit-reschedule.component';
import { TimekitBookingsComponent } from '../timekit-bookings/timekit-bookings.component';
import { TimekitFollowUpComponent } from '../timekit-follow-up/timekit-follow-up.component';
import { TimekitSettingsComponent } from '../timekit-settings/timekit-settings.component';
import { TimekitRescheduleFollowUpComponent } from '../timekit-reschedule-follow-up/timekit-reschedule-follow-up.component';
import { TimekitScheduleComponent } from '../timekit-schedule/timekit-schedule.component';

export interface ITimekitComponent {
  data: any;
}

@Component({
  selector: 'app-timekit-tabs',
  templateUrl: './timekit-tabs.component.html',
  styleUrls: ['./timekit-tabs.component.scss'],
})
export class TimekitTabsComponent implements AfterViewInit {
  @ViewChild('tabContent', { read: ViewContainerRef }) container!: ViewContainerRef;

  activeTab: number = 0;

  tabs: { title: string; component: Type<ITimekitComponent>; data: any }[] = [
    { title: 'Horario Cita Inicial', component: TimekitScheduleComponent, data: '' },
    { title: 'Horario Cita Seguimiento', component: TimekitSettingsComponent, data: '' },
    { title: 'Cita Inicial', component: TimekitBookingsComponent, data: '' },
    { title: 'Cita Seguimiento', component: TimekitFollowUpComponent, data: '' },
    { title: 'Citas Agendadas Inicial', component: TimekitRescheduleComponent, data: '' },
    { title: 'Citas Agendadas Seguimiento', component: TimekitRescheduleFollowUpComponent, data: '' },
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {}

  ngAfterViewInit(): void {
    this.loadComponent();
  }

  setActiveTab(index: number): void {
    this.activeTab = index;
    this.loadComponent();
  }

  private loadComponent(): void {
    const tab = this.tabs[this.activeTab];
    this.container.clear();

    const factory = this.componentFactoryResolver.resolveComponentFactory(tab.component);
    const componentRef = this.container.createComponent(factory, 0, this.injector);

    // Asigna datos al componente (si el componente los recibe a través de una propiedad)
    (componentRef.instance as ITimekitComponent).data = tab.data;
  }
}
