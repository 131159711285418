<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-orders">
    <div class="header">
      <h1 class="title"> {{'YOUR_PRODUCTS' | translate}}</h1>
      <button
          class="btn btn-outline-primary primary-btn"
          (click)="downloadTemplate()"
        > {{'DOWNLOAD_TEMPLATE' | translate}}</button>
      <button
        type="button"
        class="btn btn-outline-primary primary-btn"
        (click)="openProductUploadModal()"
      >
        {{'UPLOAD_FROM_TEMPLATE' | translate}}
      </button>
      <button
        type="button"
        class="btn btn-outline-primary primary-btn"
        [routerLink]="['/products/create']"
      >
         {{'CREATE_NEW_PRODUCT' | translate}}
      </button>
    </div>
    <div class="sync-buttons">
        <button
          class="btn btn-outline-primary primary-btn"
          *ngIf="themingService.shopTheming[0].shopIntegration"
          (click)="syncInventory()"
        >
           {{'SYNCHRONIZE_PRODUCTS' | translate}}
        </button>
        <button
          class="btn btn-outline-primary primary-btn"
          *ngIf="themingService.shopTheming[0].shopIntegration"
          (click)="syncImages()"
        >
          {{'SYNCHRONIZE_IMAGES' | translate}}
        </button>
    </div>
    <div class="form-card">
      <div class="search-container">
        <div class="search-string">
          <label for="" class="form-label">{{'NAME_CODE' | translate}}</label>
          <input
            type="text"
            placeholder="{{'SEARCH' | translate}}"
            [(ngModel)]="searchString"
            (keyup)="serchProducts()"
          />
        </div>
        <div class="filters-container">
          <div class="">
            <label for="" class="form-label"> {{'CREATION_DATE' | translate}}</label>
            <select class="form-select" [(ngModel)]="creationDateSort" (change)="filterSearch()">
              <option value=""> {{'NO_ORDER' | translate}}</option>
              <option value="DESC"> {{'MOST_RECENT' | translate}}</option>
              <option value="ASC"> {{'OLDER' | translate}}</option>
            </select>
          </div>
          <div class="">
            <label for="" class="form-label"> {{'QUANTITY' | translate}}</label>
            <select class="form-select" [(ngModel)]="quantitySort" (change)="filterSearch()">
              <option value=""> {{'NO_ORDER' | translate}}</option>
              <option value="DESC"> {{'HIGHER_TO_LOWER' | translate}}</option>
              <option value="ASC"> {{'LOWER_TO_HIGHER' | translate}}</option>
            </select>
          </div>
          <div class="">
            <label for="" class="form-label"> {{'STATUS' | translate}}</label>
            <select class="form-select" [(ngModel)]="isActive" (change)="filterSearch()">
              <option value=""> {{'ALL' | translate}}</option>
              <option value="1"> {{'ACTIVE' | translate}}</option>
              <option value="0">{{'INACTIVE' | translate}}</option>
            </select>
          </div>
          <div hidden>
            <button class="btn filter-btn" (click)="filterSearch()">
               {{'SEARCH' | translate}}
            </button>
          </div>
        </div>
        <div>
          <div class="dropdown">
            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              {{ 'ACTION' | translate }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" (click)="updateProductsStatus(1)">{{ 'ACTIVATE' | translate }}</a></li>
              <li><a class="dropdown-item" (click)="updateProductsStatus(0)">{{ 'DEACTIVATE' | translate }}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="no-products" *ngIf="!loading && products.length == 0">
        <span> {{'NO_PRODUCTS' | translate}}</span>
      </div>

<table *ngIf="!loading && products.length > 0" class="table-bordered table-hover">
  <thead>
    <tr class="table-header">
      <th scope="col"><input id="checkAllProducts" type="checkbox" (change)="changeSelectedProduct($event)"></th>
      <th scope="col">  {{'IMAGE' | translate}}</th>
      <th scope="col">  {{'PRODUCT' | translate}}</th>
      <th scope="col">  {{'CODE' | translate}}</th>
      <th scope="col">  {{'CATEGORIES' | translate}}</th>
      <th scope="col">  {{'QUANTITY' | translate}}</th>
      <th scope="col">  {{'EDIT' | translate}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let product of products">
      <td><input class="checkProduct" type="checkbox" [attr.data-productId]="product.productId" (change)="changeSelectedProduct($event)"></td>
      <td>
        <a [routerLink]="['/products', product.productId]" routerLinkActive="active">
          <img [src]="product.imageURL" />
        </a>
      </td>
      <td>
        <a [routerLink]="['/products', product.productId]" routerLinkActive="active">{{ product.productName }}</a>
      </td>
      <td>{{ product.productCode }}</td>
      <td>{{ product.categoryName }}</td>
      <td>{{ product.totalQuantity }}</td>
      <td>
        <button type="button" class="btn btn-primary primary-btn" [routerLink]="['/products/edit/', product.productId]">
            {{'EDIT' | translate}}
        </button>
      </td>
    </tr>
  </tbody>
</table>
    </div>

    <div class="nav-container" *ngIf="!loading && products.length > 0">
      <nav>
        <ul class="pagination">
          <li [ngClass]="{ 'page-item': true, disabled: currentPage == 1 }">
            <a
              class="page-link page-text-link"
              (click)="movePage(false)"
              tabindex="-1"
              unselectable="on"
              >  {{'PREVIOUS' | translate}}</a
            >
          </li>
          <li
            *ngIf="pages[0] >= (maxNumberOfPages - 1) / 2"
            class="page-item disabled"
          >
            <a
              class="page-link page-number-link"
              unselectable="on"
              style="opacity: 1"
              >...</a
            >
          </li>
          <li
            *ngFor="let page of pages"
            [ngClass]="{ 'page-item': true, active: currentPage == page }"
          >
            <a
              class="page-link page-number-link"
              (click)="goToPage(page)"
              unselectable="on"
              >{{ page }}</a
            >
          </li>
          <li
            *ngIf="
              currentPage + (maxNumberOfPages - 1) / 2 < totalPages &&
              totalPages > maxNumberOfPages
            "
            class="page-item disabled"
          >
            <a
              class="page-link page-number-link"
              unselectable="on"
              style="opacity: 1"
              >...</a
            >
          </li>
          <li
            [ngClass]="{
              'page-item': true,
              disabled: currentPage == totalPages
            }"
          >
            <a
              class="page-link page-text-link"
              (click)="movePage(true)"
              tabindex="-1"
              unselectable="on"
            >
                {{'NEXT' | translate}}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>

<div class="modal product-modal" id="productSyncModal" tabindex="-1">
  <div class="modal-dialog product-modal-dialog" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <div class="loading">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="productUploadModal">
  <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="title">{{ 'PRODUCT_IMPORT_BY_TEMPLATE' | translate }} </h3>
      </div>
      <div class="loading" *ngIf="ModalLoading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="modal-body h-200px" *ngIf="!ModalLoading">
        <div>
          <form id="uploadItemsForm">
            <div class="mb-3">
              <input class="form-control" type="file" accept=".xlsx" (change)="onFileSelected($event)">
            </div>
          </form>
        </div>
        <div *ngIf="isUploading">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" [style]="uploadingStyle">{{uploadPercentaje}}%</div>
          </div>
          <p>Cargando {{uploadingProducts}} de {{totalProducts}} productos con sus variantes, este proceso puede tomar tiempo según la cantidad de productos a cargar.</p>
        </div>
        <div *ngIf="!xlsxValid" class="alert alert-danger d-flex align-items-center" role="alert">
          <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
          <div>
            {{ 'INVALID_DOCUMENT_FORMAT' | translate }}
          </div>
        </div>
        <div *ngIf="xlsxValid && !isUploading" class="table-responsive">
          <table class="table-bordered table-hover">
            <thead>
              <tr *ngIf="previewDataPagination.length != 0" class="table-header">
                <th> {{'NAME' | translate}}</th>
                <th> {{'DESCRIPTION' | translate}}</th>
                <th> {{'CODE' | translate}}</th>
                <th> {{'VARIANT_1' | translate}}</th>
                <th> {{'VALUE_1' | translate}}</th>
                <th> {{'VARIANT_2' | translate}}</th>
                <th> {{'VALUE_2' | translate}}</th>
                <th> {{'VARIANT_3' | translate}}</th>
                <th> {{'VALUE_3' | translate}}</th>
                <th class="min-with-150"> {{'CATEGORIES' | translate}}</th>
                <th class="min-with-150"> {{'SUBCATEGORY' | translate}}</th>
                <th class="min-with-150">{{'SUB_SUBCATEGORY' | translate}}</th>
                <th class="min-with-200"> {{'INDUSTRY' | translate}}</th>
                <th class="min-with-200"> {{'COLLECTION' | translate}}</th>
                <th class="min-with-200"> {{'SUPPLIER' | translate}}</th>
                <th> {{'STATUS' | translate}}</th>
                <th class="min-with-150"> {{'COST_PRICE' | translate}}</th>
                <th class="min-with-200"> {{'TAX' | translate}}</th>
                <th class="min-with-150"> {{'SALE_PRICE' | translate}}</th>
                <th class="min-with-150"> {{'DISCOUNT_PRICE' | translate}}</th>
                <th> {{'QUANTITY' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cell of previewDataPagination;let index = index" class="{{getError(index).includes('no existe') || getError(index).includes('no pertenece') ? 'bg-danger' : ''}}" title="{{getError(index).includes('no existe') ? 'Una de las celdas contiene un error' : ''}}">
                <td>{{ cell.name }}</td>
                <td>{{ cell.description }}</td>
                <td>{{ cell.productCode }}</td>
                <td>{{ cell.variantType1 }}</td>
                <td>{{ cell.variantValues1 }}</td>
                <td>{{ cell.variantType2 }}</td>
                <td>{{ cell.variantValues2 }}</td>
                <td>{{ cell.variantType3 }}</td>
                <td>{{ cell.variantValues3 }}</td>
                <td>{{ getCategoryName(cell.categoryId) }}</td>
                <td>{{ getSubCategoryName(cell.subCategoryId, cell.categoryId) }}</td>
                <td>{{ getSubSubCategoryName(cell.subSubCategoryId, cell.subCategoryId) }}</td>
                <td>{{ getIndustryName(cell.industryId) }}</td>
                <td>{{ getCollectionName(cell.collectionId) }}</td>
                <td>{{ getSupplierName(cell.supplierId) }}</td>
                <td>{{ getStateName(cell.state) }}</td>
                <td>{{ cell.costPrice }}</td>
                <td>{{ getTaxName(cell.taxId) }}</td>
                <td>{{ cell.salePrice }}</td>
                <td>{{ cell.discountPrice }}</td>
                <td>{{ cell.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="previewTotalPages > 1 && !isUploading" class="nav-container">
          <nav>
            <ul class="pagination">
              <li [ngClass]="{ 'page-item': true, disabled: previewPageNumber == 1 }">
                <a
                  class="page-link page-text-link"
                  (click)="movePreviewPage(false)"
                  tabindex="-1"
                  unselectable="on"
                  >  {{'PREVIOUS' | translate}}</a
                >
              </li>
              <li
                *ngFor="let page of previewPages"
                [ngClass]="{ 'page-item': true, active: previewPageNumber == page }"
              >
                <a
                  class="page-link page-number-link"
                  (click)="goToPreviewPage(page)"
                  unselectable="on"
                  >{{ page }}</a
                >
              </li>
              <li
                [ngClass]="{
                  'page-item': true,
                  disabled: previewPageNumber == previewTotalPages
                }"
              >
                <a
                  class="page-link page-text-link"
                  (click)="movePreviewPage(true)"
                  tabindex="-1"
                  unselectable="on"
                >
                    {{'NEXT' | translate}}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div *ngIf="xlsxValid && !isUploading && !previewError" class="modal-footer justify-content-end">
        <button
          class="btn btn-danger cancel-btn"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          {{'CLOSE' | translate}}
        </button>
        <button
          class="btn btn-outline-primary primary-btn"
          (click)="confirmUploadProduct()"
          [disabled]="previewData.length == 0"
        >  {{'TO_PROCESS' | translate}}</button>
      </div>

      <div *ngIf="previewError" class="modal-footer justify-content-end">
        <button
          class="btn btn-danger cancel-btn"
          (click)="showErrorLines()"
        >  {{'SEE_ERRORS' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal result-modal" tabindex="-1" role="dialog" id="processConfirmation">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        </button>
      </div>
      <div class="modal-body">
        <p>Va a procesar {{totalProducts}} productos con sus variantes, esta operación puede demorar, ¿Desea continuar?.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success confirm-btn" (click)="uploadProduct()">{{'CONFIRM' | translate}}</button>
        <button type="button" class="btn btn-danger cancel-btn" (click)="cancelProcess()">{{'CANCELLED' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal result-modal" tabindex="-1" role="dialog" id="processResult">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        </button>
      </div>
      <div class="modal-body">
        <p>Se procesaron {{totalProducts}} productos con sus variantes, hubo {{uploadErrors.length}} errores.</p>
      </div>
      <div class="modal-footer">
        <button  type="button" *ngIf="uploadErrors.length === 0" data-bs-dismiss="modal" class="btn btn-success confirm-btn visually-hidden" (click)="getProducts()">{{'ACCEPT' | translate}}</button>
        <button  type="button" *ngIf="uploadErrors.length !== 0" class="btn btn-success confirm-btn" (click)="downloadErrors()">{{ 'DOWNLOAD_ERRORS' | translate }}</button>
        <button  type="button" class="btn btn-success confirm-btn" (click)="exportItems()">{{ 'DOWNLOAD_PRODUCTS' | translate }}</button>
        <button type="button" data-bs-dismiss="modal" class="btn btn-danger cancel-btn visually-hidden" (click)="cancelProcess()">  {{'CANCEL' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal result-modal" tabindex="-1" role="dialog" id="productSyncErrorModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        </button>
      </div>
      <div class="modal-body">
        <p>{{ 'SYNC_ERROR' | translate }}</p>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="downloadModal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-sm modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <button type="button" class="btn-close" (click)="closeModal()"></button>
        <p>{{ 'WAIT_FOR_DOWNLOAD' | translate }}</p>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="successBulkEdit" tabindex="-1">
  <div class="modal-dialog product-modal-dialog" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'CHANGES_SAVED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-danger cancel-btn" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="errorBulkEdit" tabindex="-1">
  <div class="modal-dialog product-modal-dialog" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-danger cancel-btn" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
