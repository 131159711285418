<div class="container">
  <div class=" container-inventory">
		<div class="header">
				<h1 class="title">{{ 'ADD_PAYMENT_METHOD' | translate }}</h1>
				<app-back-arrow-settings [callback]="setListingPaymentMethods"></app-back-arrow-settings>
		</div>

    <div class="form-card">
      <h3 class="subtitle">
        {{ 'PAYMENT_METHOD_TYPES' | translate }}
      </h3>
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="row" *ngIf="!loading">
        <div class="col-md-6" *ngFor="let paymentType of paymentTypes">
          <div class="form-check">
            <input class="form-check-input " type="radio" name="paymentType" value="{{paymentType.paymentTypeId}}" [disabled]="paymentType.hasConfig == true" (change)="selectPaymentToCreate($event)">
            <label class="form-check-label" *ngIf="paymentType.paymentTypeId != 1 && paymentType.paymentTypeId != 8">
              {{paymentType.paymentTypeName}}
            </label>
            <label class="form-check-label" *ngIf="paymentType.paymentTypeId == 1">
              {{paymentType.paymentTypeName}} - FAC BAC
            </label>
            <label class="form-check-label" *ngIf="paymentType.paymentTypeId == 8">
              {{paymentType.paymentTypeName}} - XpressPago
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-card" *ngIf="paymentTypeIdToCreate > 0">
      <!-- CREDIT CARD -->
      <div class="row" *ngIf="paymentTypeIdToCreate == 1">
        <div class="col-md-10 d-flex align-items-center">
          <h3 class="subtitle">
            {{ 'CREDIT_DEBIT_CARDS' | translate }}
          </h3>
        </div>
        <div class="col-md-2">
          <div class="switch">
            <label class="switch-label">
                <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                <span class="slider round"></span>
            </label>
          </div>
          <div>
            <span id="statusText"  class="status-text">{{ isActive ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</span>
          </div>
        </div>
        <p>{{ 'BAC_FAC_GATEWAY_INFO' | translate }}</p>
        <form [formGroup]="creditCardForm">
          <div class="col-12">
            <label class="form-label is-required">{{'ENVIRONMENT' | translate}}</label>
            <select class="form-control" formControlName="enviroment">
              <option value="staging" selected>{{ 'TEST_ENVIRONMENT' | translate }}</option>
              <option value="prod">{{ 'PRODUCTION' | translate }}</option>
            </select>
          </div>
          <div class="col-12">
            <label class="form-label is-required">Id</label>
            <input type="text" class="form-control" formControlName="user" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">{{'PASSWORD' | translate}}</label>
            <input type="text" class="form-control" formControlName="password" placeholder=""/>
          </div>
          <div class="col-12 d-flex justify-content-center form-buttons">
            <button type="button" class="btn submit-btn" (click)="validateFormData()">
              {{'ADD' | translate}}
            </button>
          </div>
        </form>
      </div>
      <!-- SINPE MOVIL -->
      <div class="row" *ngIf="paymentTypeIdToCreate == 2">
        <div class="col-md-10 d-flex align-items-center">
          <h3 class="subtitle">
            Sinpe Móvil
          </h3>
        </div>
        <div class="col-md-2">
          <div class="switch">
            <label class="switch-label">
                <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                <span class="slider round"></span>
            </label>
          </div>
          <div>
            <span id="statusText"  class="status-text">{{ isActive ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</span>
          </div>
        </div>
        <form [formGroup]="sinpeMovilForm">
          <div class="col-12">
            <label class="form-label is-required">{{'NUMBER' | translate}}</label>
            <input type="text" class="form-control" formControlName="phone" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label">{{ 'PAYMENT_DETAILS' | translate }}</label>
            <textarea class="form-control" formControlName="description" rows="4" placeholder="A nombre de Mobydyg"></textarea>
            <small class="form-text">
              {{ 'ADDITIONAL_MESSAGE_PAYMENT_METHOD' | translate }}
              {{ 'ADDITIONAL_MESSAGE_SINPE' | translate }}
            </small>
          </div>
          <div class="col-12 d-flex justify-content-center form-buttons">
            <button type="button" class="btn submit-btn" (click)="validateFormData()">
              {{'ADD' | translate}}
            </button>
          </div>
        </form>
      </div>
      <!-- TRANSFER -->
      <div class="row" *ngIf="paymentTypeIdToCreate == 3">
        <div class="col-md-10 d-flex align-items-center">
          <h3 class="subtitle">
            {{'BANK_TRANSFER' | translate}}
          </h3>
        </div>
        <div class="col-md-2">
          <div class="switch">
            <label class="switch-label">
                <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                <span class="slider round"></span>
            </label>
          </div>
          <div>
            <span id="statusText"  class="status-text">{{ isActive ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</span>
          </div>
        </div>
        <p>{{ 'BANK_ACCOUNTS_FOR_TRANSFER' | translate }}</p>
        <form [formGroup]="transferForm">
          <div class="col-12">
            <label class="form-label is-required">Cuenta IBAN</label>
            <input type="text" class="form-control" formControlName="iban" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label">Cuenta BAC</label>
            <input type="text" class="form-control" formControlName="bac" placeholder=""/>
          </div>
          <div class="col-12 visually-hidden">
            <label class="form-label">Cuenta Banco Nacional</label>
            <input type="text" class="form-control" formControlName="bn" placeholder=""/>
          </div>
          <div class="col-12 visually-hidden">
            <label class="form-label">Cuenta Banco de Costa Rica</label>
            <input type="text" class="form-control" formControlName="bcr" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label">{{'DESCRIPTION' | translate}}</label>
            <textarea class="form-control" formControlName="description" rows="4" placeholder="A nombre de Mobydyg"></textarea>
            <small class="form-text">
              {{ 'ADDITIONAL_MESSAGE_PAYMENT_METHOD' | translate }}
              {{ 'ADDITIONAL_MESSAGE_BANK' | translate }}
            </small>
          </div>
          <div class="col-12 d-flex justify-content-center form-buttons">
            <button type="button" class="btn submit-btn" (click)="validateFormData()">
              {{'ADD' | translate}}
            </button>
          </div>
        </form>
      </div>
       <!-- PAYVALIDA -->
       <div class="row" *ngIf="paymentTypeIdToCreate == 5">
        <div class="col-md-10 d-flex align-items-center">
          <h3 class="subtitle">
            PayValida
          </h3>
        </div>
        <div class="col-md-2">
          <div class="switch">
            <label class="switch-label">
                <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                <span class="slider round"></span>
            </label>
          </div>
          <div>
            <span id="statusText" class="status-text">{{ isActive ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</span>
          </div>
        </div>
        <form [formGroup]="payValidaForm">
          <div class="col-12">
            <label class="form-label is-required">Merchant Id</label>
            <input type="text" class="form-control" formControlName="merchantId" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">Client Secret</label>
            <input type="text" class="form-control" formControlName="clientSecret" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">Notification Secret</label>
            <input type="text" class="form-control" formControlName="notificationSecret" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">{{'COUNTRY_CODE' | translate}}</label>
            <input type="text" class="form-control" formControlName="countryCode" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">{{'CURRENCY_CODE' | translate}}</label>
            <input type="text" class="form-control" formControlName="currencyCode" placeholder=""/>
          </div>
          <div class="col-12 d-flex justify-content-center form-buttons">
            <button type="button" class="btn submit-btn" (click)="validateFormData()">
              {{'ADD' | translate}}
            </button>
          </div>
        </form>
      </div>
      <!-- XPRESSPAGO -->
      <div class="row" *ngIf="paymentTypeIdToCreate == 8r">
        <div class="col-md-10 d-flex align-items-center">
          <h3 class="subtitle">
            {{ 'CREDIT_DEBIT_CARDS' | translate }}
          </h3>
        </div>
        <div class="col-md-2">
          <div class="switch">
            <label class="switch-label">
                <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                <span class="slider round"></span>
            </label>
          </div>
          <div>
            <span id="statusText"  class="status-text">{{ isActive ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</span>
          </div>
        </div>
        <p>{{ 'BAC_XP_GATEWAY_INFO' | translate }}</p>
        <form [formGroup]="creditCardXPForm">
          <div class="col-12">
            <label class="form-label is-required">{{'ENVIRONMENT' | translate}}</label>
            <select class="form-control" formControlName="enviroment">
              <option value="staging" selected>{{ 'TEST_ENVIRONMENT' | translate }}</option>
              <option value="prod">{{ 'PRODUCTION' | translate }}</option>
            </select>
          </div>
          <div class="col-12">
            <label class="form-label is-required">Id</label>
            <input type="text" class="form-control" formControlName="user" placeholder=""/>
          </div>
          <div class="col-12">
            <label class="form-label is-required">{{'PASSWORD' | translate}}</label>
            <input type="text" class="form-control" formControlName="password" placeholder=""/>
          </div>
          <div class="col-12 d-flex justify-content-center form-buttons">
            <button type="button" class="btn submit-btn" (click)="validateFormData()">
              {{'ADD' | translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
   </div>
</div>

<div class="modal paymentMethod-confirm-modal" id="paymentMethodLoadingModal" tabindex="-1">
	<div class="modal-dialog paymentMethod-modal-dialog" role="document">
		<div class="modal-content paymentMethod-modal-content">
			<div class="modal-body paymentMethod-modal-body">
        <h3 class="text-center">Procesando...</h3>
				<div class="loading">
					<div class="spinner-border" role="status"></div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal paymentMethod-confirm-modal" id="successPaymentMethodsModal" tabindex="-1">
	<div class="modal-dialog paymentMethod-modal-dialog" role="document">
		<div class="modal-content paymentMethod-modal-content">
			<div class="modal-body paymentMethod-modal-body">
				<h3 class="text-center">{{'SUCCESS' | translate}}</h3>
				<p class="text-center">{{ 'PAYMENT_METHOD_ADDED_SUCCESS' | translate }}</p>
			</div>
			<div class="modal-buttons">
				<button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="modal error-confirm-modal fade" id="requiredPaymentMethodModal" tabindex="-1">
	<div class="modal-dialog error-modal-dialog" role="document">
		<div class="modal-content error-modal-content">
			<div class="modal-body error-modal-body">
				<h3 class="text-center">{{'ERROR' | translate}}</h3>
				<p class="text-center">{{ 'COMPLETE_REQUIRED_FIELDS_CORRECTLY' | translate }}</p>
			</div>
			<div class="modal-buttons">
				<button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="modal paymentMethod-confirm-modal fade" id="errorPaymentMethodsModal" tabindex="-1">
	<div class="modal-dialog paymentMethod-modal-dialog" role="document">
		<div class="modal-content paymentMethod-modal-content">
			<div class="modal-body paymentMethod-modal-body">
				<h3 class="text-center">{{'ERROR' | translate}}</h3>
				<p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
			</div>
			<div class="modal-buttons">
				<button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
			</div>
		</div>
	</div>
</div>

