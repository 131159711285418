<div class="container">
  <div class="container-category">

    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div *ngIf="!loading" class="form-group-container">
        <div class="header">
          <h1 class="title">{{ 'EDIT_SUB_SUBCATEGORY' | translate }}</h1>
        </div>

        <div class="form-group trio">
          <div class="col-md-4">
            <label class="form-label" for="product_name">{{'NAME' | translate}}</label>
            <input type="text" class="form-control" id="subSubCategoryName" placeholder="{{ 'SUB_SUBCATEGORY_NAME' | translate }}"
            (keyup)="changeSubSubCategoryName($event)" value="{{subSubCategory.subSubCategoryName}}" #subSubCategoryName/>
          </div>
          <div class="col-md-3">
            <label class="form-label" for="product_category">{{ 'PARENT_SUBCATEGORY' | translate }}</label>
            <select class="form-select" id="categoryParentCategory" (change)="changeSubSubCategoryParentId($event)"
              #categoryParentCategory [(ngModel)]="subSubCategory.subCategoryId">
              <option selected disabled hidden>{{ 'SELECT_PARENT_SUBCATEGORY' | translate }}</option>
              <option *ngFor="let parentcategory of subCategories" [value]="parentcategory.subCategoryId">
              {{ parentcategory.subCategoryName }}
            </option>
            </select>
          </div>
          <div class="form-group-image col-md-4">
            <label class="form-label">Banner</label>
            <div class="image-container">
              <input type="file" id="optionImage" class="form-control-file" (change)="onImageChange($event)" hidden>
              <div *ngIf="imageUrl" class="image-preview">
                <img [src]="imageUrl" alt="Imagen de subcategoría previsualizada" class="img-thumbnail">
              </div>
              <div class="btn-container">
                <a *ngIf="imageUrl" class="remove-btn" (click)="removeImage()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/></svg>
                </a>
               <a class="add-btn" (click)="triggerFileInput()">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 256 256"><path fill="#5E35B1" d="M208 56h-27.72L169 39.12A16 16 0 0 0 155.72 32h-55.44A16 16 0 0 0 87 39.12L75.72 56H48a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h160a24 24 0 0 0 24-24V80a24 24 0 0 0-24-24m-48 88h-24v24a8 8 0 0 1-16 0v-24H96a8 8 0 0 1 0-16h24v-24a8 8 0 0 1 16 0v24h24a8 8 0 0 1 0 16"/></svg>
               </a>
              </div>
            </div>
          </div>
        </div>
          <div class="form-buttons">
            <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
              {{'SAVE' | translate}}
            </button>
          </div>
      </div>
      </div>
    </div>
  </div>

<div class="modal category-confirm-modal fade" id="successSubSubCategoryModal" tabindex="-1">
<div class="modal-dialog category-modal-dialog" role="document">
  <div class="modal-content category-modal-content">
    <div class="modal-body category-modal-body">
      <h3 class="text-center">{{'SUCCESS' | translate}}!</h3>
      <p class="text-center">{{ 'SUB_SUBCATEGORY_SAVED_SUCCESS' | translate }}</p>
    </div>
    <div class="modal-buttons">
      <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
    </div>
  </div>
</div>
</div>

<div class="modal category-confirm-modal fade" id="errorSubSubCategoryModal" tabindex="-1">
<div class="modal-dialog category-modal-dialog" role="document">
  <div class="modal-content category-modal-content">
    <div class="modal-body category-modal-body">
      <h3 class="text-center">{{'ERROR' | translate}}!</h3>
      <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
    </div>
    <div class="modal-buttons">
      <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
    </div>
  </div>
</div>
</div>
