<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-client">
    <div class="header">
      <h1 class="title">{{'CLIENT' | translate}}</h1>
      <app-back-arrow [route]="'/clients'"></app-back-arrow>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div *ngIf="!loading" class="content row">
        <div class="title">
          <span>{{'INFORMATION' | translate}}</span>
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="">{{'NAME' | translate}}</label>
        <input class="form-control" type="text" value="{{ client?.firstName || '' }}" disabled>
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="">{{ 'LAST_NAME' | translate }}</label>
          <input class="form-control" type="text" value="{{ client?.lastName }}" disabled>
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="">{{ 'GENDER' | translate }}</label>
          <div class="">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="gender" id="" value="male" [checked]="client?.gender == 'male'" disabled>
              <label class="form-check-label" for="">{{ 'MALE' | translate }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="gender" id="" value="female" [checked]="client?.gender == 'female'" disabled>
              <label class="form-check-label" for="">{{ 'FEMALE' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="column col-md-6">
          <label class="form-label" for="">{{'EMAIL' | translate}}</label>
        <input class="form-control" type="text" value="{{ client?.email }}" disabled>
        </div>
        <div class="column col-md-6">
          <label class="form-label" for="">{{'PHONE' | translate}}</label>
        <input class="form-control" type="text" value="{{ client?.phone }}" disabled>
        </div>
      </div>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="title" *ngIf="!loading">
        <span>Pedidos</span>
      </div>
      <div class="content container-fluid" *ngIf="!loading">
        <div class="no-orders" *ngIf="sales.length == 0">
          <span>No existen pedidos</span>
        </div>
        <div class="col-sm-12 sale-card" *ngFor="let sale of sales">
          <div class="sale-card-header">
            <span class="header-title">
              {{'ORDER' | translate}} #{{ sale.saleShortCode }}
            </span>
            <span class="header-date">
              {{ sale.saleOnlyDate }}
            </span>
          </div>
          <div class="sale-card-body">
            <div class="sale-card-info">
              <span class="info-title">
                {{"STATUS" | translate}}:
              </span>
              <span class="status">
                {{ saleStateName[sale.saleState] }}
              </span>
            </div>
            <div class="sale-card-info">
              <span class="info-title">
                Tipo de entrega:
              </span>
              <span class="shipping">
                {{ sale.expressShipping == 1 ? "Envío a domicilio" : "Recoger en tienda" }}
              </span>
            </div>
            <div class="sale-card-info">
              <span>
                <button class="btn primary-btn" [routerLink]="['/orders/', sale.saleId]">
                  {{ 'GO_TO_ORDER' | translate }}
                </button>
              </span>
            </div>
            <div class="card-price">
              <span class="price-title">
                {{'TOTAL' | translate}}
              </span>
              <span class="amount">
                {{ appComponent.formatMoney(sale.saleEndPrice) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
