import { Component, Input } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { ITimekitComponent } from '../timekit-tabs/timekit-tabs.component';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';

interface Appointment {
  id: string;
  state: string;
  graph: string;
  completed: boolean;
  created_at: string;
  updated_at: string;
  start?: string;
  end?: string;
  location?: string;
  customers: Customer[];
}

interface Customer {
  email: string;
  id: string;
  name: string;
  phone: string;
  telefono: string;
  timezone: string;
  tipoCita: string;
  'Lugar de Cita': string;
  voip: string;
}

interface Slot {
  start: string;
  end: string;
  resources: { name: string; id: string; timezone: string }[];
}

interface ApiResponse {
  statusCode: number;
  body: string;
  headers?: { [key: string]: string };
}

type ApiResponseData = Slot[];


@Component({
  selector: 'app-timekit-reschedule',
  templateUrl: './timekit-reschedule.component.html',
  styleUrls: ['./timekit-reschedule.component.scss'],
})

export class TimekitRescheduleComponent implements ITimekitComponent {
  @Input() data: any;


  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, bootstrap5Plugin, momentTimezonePlugin],
    timeZone: 'America/Costa_Rica',
    themeSystem: 'bootstrap5',
    initialView: 'dayGridMonth',
    locale: esLocale,
    events: [],
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: true,
    },
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    },
    eventClick: this.handleEventClick.bind(this),
  };


  isModalOpen: boolean = false;
  availableSlots: any[] = [];
  availabilityResponse: any;
  selectedAppointment: any;
  isAvailabilityModalOpen: boolean = false;
  modalCancelAppointment = false;
  modalSuccess = false;
  projectId = 'd7b3342e-9cf5-4ead-a986-e9fc720b542e';
  availabilityData: any;

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
  ) { }

  ngOnInit() {
    this.loadAppointments();
    this.getAvailability();
    this.getBookings();
  }

  getFormattedDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { weekday: 'long', month: 'long', day: 'numeric' });
  }

  getFormattedTime(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  }

  async getAvailability() {
    try {
      const response = await this.apiService.getAvailability(this.themingService.shopId, this.projectId) as ApiResponse;

      let data;
      try {
        data = JSON.parse(response.body);
      } catch (parseError) {
        console.error('Error parsing JSON:', parseError);
        return;
      }

      if (Array.isArray(data)) {
        this.availabilityData = data;
        this.loadAppointments();
      } else {
        console.error('Availability data is not an array');
      }
    } catch (error) {
      console.error('Error getting availability:', error);
    }
  }

  loadAppointments() {
    if (!this.availabilityData || !Array.isArray(this.availabilityData)) {
      return;
    }

    const events = this.availabilityData.map((appointment: any) => {
      const start = new Date(appointment.attributes.event_info.start);
      const end = new Date(appointment.attributes.event_info.end);

      return {
        id: appointment.id,
        title: appointment.customers?.[0]?.name || 'Nombre no disponible',
        start: start.toISOString(),
        end: end.toISOString(),
        formattedStartDate: this.getFormattedDate(start.toISOString()),
        formattedStartTime: this.getFormattedTime(start.toISOString()),
        formattedEndTime: this.getFormattedTime(end.toISOString()),
        display: 'block',
        className: 'my-custom-event',
        extendedProps: { appointment }
      };
    });

    this.calendarOptions.events = events;
  }

  handleEventClick(arg: any) {
    const selected = arg.event.extendedProps.appointment;

    if (selected) {
      this.selectedAppointment = {
        id: selected.id || arg.event.id,
        state: selected.state || 'unknown',
        graph: selected.graph || 'unknown',
        completed: selected.completed || false,
        created_at: selected.created_at || new Date().toISOString(),
        updated_at: selected.updated_at || new Date().toISOString(),
        location: selected.attributes?.event_info?.where || '',
        customers: [{
          name: selected.attributes?.customer?.name || 'No disponible',
          email: selected.attributes?.customer?.email || 'No disponible',
          telefono: selected.attributes?.customer?.telefono || 'No disponible',
          lugar_de_cita: selected.attributes?.customer?.lugar_de_cita || 'No disponible',
          tipocita: selected.attributes?.customer?.tipo_de_cita || 'No disponible' // Mapeamos el tipo de cita aquí
        }],
        start: selected.attributes?.event_info?.start || new Date().toISOString(),
        end: selected.attributes?.event_info?.end || new Date(new Date().getTime() + 60 * 60 * 1000).toISOString()
      };

      this.openModal();
    }
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  async getBookings(): Promise<ApiResponseData> {
    try {
      const response = await this.apiService.getBookings(this.themingService.shopId, this.projectId) as ApiResponse;

      const data: ApiResponseData = JSON.parse(response.body);


      return data;
    } catch (error) {
      console.error('Error al obtener las reservas:', error);
      throw error;
    }
  }

  async updateAppointment() {
    if (this.selectedAppointment) {
      if (!this.selectedAppointment.start || !this.selectedAppointment.end) {
        return;
      }

      try {
        const responseData = await this.getBookings();

        if (Array.isArray(responseData)) {
          this.availableSlots = responseData.map(slot => {
            const formattedStart = this.getFormattedDate(slot.start);
            const startTime = this.getFormattedTime(slot.start);
            const endTime = this.getFormattedTime(slot.end);

            return {
              ...slot,
              formattedStart,
              startTime,
              endTime
            };
          });
        } else {
          this.availableSlots = [];
        }

        if (this.availableSlots.length === 0) {
        } else {
          console.log('Datos de disponibilidad:', this.availableSlots);
        }

        this.openAvailabilityModal();

      } catch (error) {
        console.error('Error al verificar disponibilidad:', error);
        this.availableSlots = [];
        this.openAvailabilityModal();
      }
    }

    setTimeout(() => {
      this.isModalOpen = false;
      this.loadAppointments();
    }, 100);
  }

  openAvailabilityModal() {
    this.isAvailabilityModalOpen = true;
  }

  closeAvailabilityModal() {
    this.isAvailabilityModalOpen = false;
  }

  async rescheduleBookings() {
    try {
      const { start, end, id } = this.selectedAppointment;
      const response = await this.apiService.rescheduleBookings(
        this.themingService.shopId,
        this.projectId,
        id,
        start,
        end
      );
      return response;
    } catch (error) {
      console.error('Error rescheduling appointment:', error);
      throw error;
    }
  }

  selectSlot(slot: Slot) {
    if (this.selectedAppointment && this.selectedAppointment.id) {

      this.selectedAppointment.start = slot.start;
      this.selectedAppointment.end = slot.end;

      this.closeAvailabilityModal();

      this.rescheduleBookings()
        .then(data => {
          this.getAvailability();
        })
        .catch(error => {
          console.error('Error rescheduling appointment:', error);
        });
    } else {
      console.error('Error: No appointment selected or appointment ID is undefined');
    }
  }

  private handleError(response: Response) {
    if (!response.ok) {
      return response.text().then(text => {
        throw new Error(`Error ${response.status}: ${response.statusText}\n${text}`);
      });
    }
    return response;
  }

  async cancelBooking() {
    try {
      const { id } = this.selectedAppointment;
      const response = await this.apiService.cancelBooking(
        this.themingService.shopId,
        this.projectId,
        id
      );
      return response;
    } catch (error) {
      console.error('Error canceling appointment:', error);
      throw error;
    }
  }

  cancelAppointment() {
    if (this.selectedAppointment) {
      this.modalCancelAppointment = true;
    }
  }

  async handleCancelConfirmation(confirm: boolean) {
    if (confirm && this.selectedAppointment) {
      try {
        await this.cancelBooking();
        this.modalCancelAppointment = false;
        this.modalSuccess = true;
        this.isModalOpen = false;
        this.getAvailability();
      } catch (error) {
        console.error('Error during cancel confirmation:', error);
        this.modalCancelAppointment = false;
      }
    } else {
      this.modalCancelAppointment = false;
    }
  }

  closeModalAppointment() {
    this.modalSuccess = false;
  }
}

