<app-side-bar></app-side-bar>

<div class="container">
  <div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="container-product" *ngIf="!loading">
    <div class="header">
      <h1 class="title">{{'EDIT_PRODUCT' | translate}} {{product.product.productCode}}</h1>
      <app-back-arrow [routerLink]="['/products', productId]" routerLinkActive="router-link-active"></app-back-arrow>
    </div>

    <div class="form-card">
      <input type="hidden" id="product_id" name="id" value="{{productId}}" />

      <div class="info-container">
        <div class="form-label">{{'STATUS' | translate}}:</div>
        <label class="switch">
          <input type="checkbox" [(ngModel)]="product.product.productIsActive" id="flexCheckChecked">
          <span class="slider round"></span>
        </label>
        <span class="switch-text">{{ product.product.productIsActive ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</span>
      </div>

      <div class="form-label">{{'IMAGES' | translate}}:</div>
      <div class="images-container">
        <div cdkDropList cdkDropListOrientation="horizontal" class="image-list" (cdkDropListDropped)="drop($event)">
          <div class="image-box" cdkDragDisabled>
            <button class="add-img" (click)="selectImage(0)">+</button>
            <input id="btn-upload" type="file" class="d-none" (change)="onFileSelected($event)">
          </div>
          <div class="image-box" *ngFor="let image of generalImages; let i = index" cdkDrag>
            <div class="image-placeholder" *cdkDragPlaceholder></div>
            <img [src]="image.url" />
            <button type="button" class="btn btn-danger delete-button" (click)="deleteProductImage(image.url)">
              x
            </button>
            <!-- <button type="button" class="btn btn-link download-button" (click)="downloadImage(i)">
              <i class="feather" data-feather="download"></i>
            </button> -->
          </div>
        </div>
      </div>

      <div class="form-group-container">
        <div class="form-group couple">
          <label class="form-label" for="product_name">{{'NAME' | translate}}</label>
          <input type="text" class="form-control" id="product_name" name="name" placeholder="Nombre del producto"
            value="{{product.product.productName}}" (keyup)="changeName($event)"/>
        </div>
      </div>

      <div class="form-group-container">
        <div class="form-group trio">
          <label class="form-label" for="product_category">{{'CATEGORY' | translate}}</label>
          <select class="form-select" id="product_category" name="category" (change)="changeCategory($event); resetSubcategories()">
            <option value="0" selected disabled hidden>{{'SELECT_CATEGORY' | translate}}</option>
            <option *ngFor="let category of categories" [value]="category.categoryId" [selected]="category.categoryId == product.product.productCategoryId">
              {{ category.categoryName }}
            </option>
          </select>
        </div>
        <div class="form-group trio">
          <label class="form-label" for="product_subcategory">{{"SUBCATEGORY" | translate}}</label>
          <select class="form-select" id="product_subcategory" name="subcategory" (change)="changeSubCategory($event); resetSubSubcategories()">
            <option value="0">{{'SELECT_SUBCATEGORY' | translate}}</option>
            <ng-container *ngFor="let subCategory of subCategories">
              <option [value]="subCategory.subCategoryId" [selected]="subCategory.subCategoryId == product.product.productSubCategoryId">
                {{ subCategory.subCategoryName }}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="form-group trio">
          <label class="form-label" for="product_subsubcategory">{{'SUB_SUBCATEGORY' | translate}}</label>
          <select class="form-select" id="product_subsubcategory" name="subsubcategory" (change)="changeSubSubCategory($event)">
            <option value="0">{{'SELECT_SUB_SUBCATEGORY' | translate}}</option>
            <ng-container *ngFor="let subSubCategory of subSubCategories">
              <option [value]="subSubCategory.subSubCategoryId" [selected]="subSubCategory.subSubCategoryId == product.product.productSubSubCategoryId">
                {{ subSubCategory.subSubCategoryName }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="form-group-container">
        <div class="form-group trio">
          <label class="form-label" for="product_industryType">{{'INDUSTRY' | translate}}</label>
          <select class="form-select" id="product_industryType" name="industryType" (change)="changeIndustry($event)">
            <option selected disabled hidden>{{'SELECT_INDUSTRY' | translate}}</option>
            <option *ngFor="let industryType of industryTypes" [value]="industryType.industryTypeId" [selected]="industryType.industryTypeId==product.product.productIndustryTypeId">
              {{ industryType.industryTypeName }}
            </option>
          </select>
        </div>
        <div class="form-group trio">
          <label class="form-label" for="product_supplier">{{'SUPPLIER' | translate}}</label>
          <select class="form-select" id="product_supplier" name="supplier" (change)="changeSupplier($event)">
            <option  value="0">{{"SELECT_SUPPLIER" | translate}}</option>
            <option *ngFor="let supplier of suppliers" [value]="supplier.supplierId" [selected]="supplier.supplierId==product.product.productSupplierId">
              {{ supplier.supplierName }}
            </option>
          </select>
        </div>

        <div class="form-group trio">
          <label class="form-label" for="product_collection">{{'COLLECTION' | translate}}</label>
          <select class="form-select" id="product_collection" name="collection" (change)="changeCollection($event)">
            <option  value="0">{{'SELECT_COLLECTION' | translate}}</option>
            <option *ngFor="let collection of collections" [value]="collection.collectionId" [selected]="collection.collectionId==product.product.productCollectionId">
              {{ collection.collectionName }}
            </option>
          </select>
        </div>

      </div>

      <div class="info-container description">
        <div class="form-label">{{'DESCRIPTION' | translate}}:</div>
        <div class="description-card ql-editor visually-hidden" id="description"></div>
      </div>
      <!-- [customOptions]="customOptions" -->
      <quill-editor class="description-editor" [styles]="{
                  borderRadius: '0 0 10px 10px',
                  padding: '5px',
                  marginBottom: '2vh'
                }" [theme]="'snow'" id="description-edit" [modules]="modules" [(ngModel)]="product.product.productDescription"
        (onEditorChanged)="descriptionChanged($event)"></quill-editor>
    </div>

    <h1 class="title">{{'PRICE' | translate}}</h1>
    <div class="form-card">
      <div class="form-group-container">
        <div class="form-group trio">
          <label class="form-label" for="product_cost">{{'COST_PRICE' | translate}}</label>
          <input type="number" min="0" class="form-control" id="product_cost" name="cost" placeholder="0.00"
            value="{{product.product.productBaseCost}}" (input)="changeBaseCost($event)"  />
        </div>
        <div class="form-group trio">
          <label class="form-label" for="product_gainmargin">{{'MARGIN'| translate}}</label>
          <input type="number" min="0" class="form-control" id="product_gainmargin" name="gainmargin" placeholder="0%"
            value="{{product.product.gainMargin}}" (input)="changeGainMargin($event)"/>
        </div>
        <div class="form-group trio">
          <label class="form-label" for="product_tax">{{'TAX' | translate}}</label>
          <select class="form-select" id="product_tax" name="tax" (change)="changeTax($event)" >
            <option *ngFor="let tax of taxes" [value]="tax.taxId" [selected]="product.product.productTaxId == tax.taxId">
              {{ tax.taxName }}
            </option>
          </select>
        </div>
      </div>

      <hr class="page-separator">

      <div class="form-group-container">
        <div class="form-group trio">
          <label class="form-label" for="product_endprice">{{'SALE_PRICE' | translate}}</label>
          <input type="number" min="0" class="form-control" id="product_endprice" name="endprice" placeholder="Precio final"
            value="{{product.product.productSellPrice || 0}}" (input)="changeSellPrice($event)" [disabled]="false"/>
        </div>
        <div class="form-group trio">
          <label class="form-label" for="product_sale">{{'DISCOUNT_PRICE' | translate}}</label>
          <input type="number" min="0" class="form-control" id="product_sale" name="sale" placeholder="0" (keyup)="changeDiscountPrice($event)" value="{{ product.product.productDiscountPrice || 0 }}" />
        </div>
        <div class="form-group trio"></div>
      </div>
    </div>

    <div class="buttons-variants-container">
      <h1 class="title">{{'VARIANTS' | translate}}</h1>
      <button type="button" class="btn btn-success confirm-btn visually-hidden" >
        {{ 'ADD_VARIANTS' | translate }}
      </button>
    </div>

    <div class="variant-container form-card" *ngFor="let variant of variants;let indexOfelement = index;">
      <div>
        <label class="form-label" for="product_variable_type">
          {{'TYPE' | translate}}
        </label>
        <input list="product_variable_type1" name="varieble_type" class="form-control"
          placeholder="{{ 'ENTER_TYPE' | translate }}" value="{{variant.variantTypeName}}" id="type{{indexOfelement}}" (keyup)="changeVariantTypeName($event)"/>
        <datalist id="product_variable_type1">
          <option value="Tamaño">
          <option value="Color">
          <option value="Material">
          <option value="Estilo">
        </datalist>
      </div>
      <div class="value-label" >
        <label class="form-label" for="product_variable_value">{{'VALUE' | translate}}</label>
        <button id="valBtn1" type="button" class="btn btn-outline-secondary small-btn-value confirm-btn text-white" (click)="addVariantValue(indexOfelement)" [disabled]="emptyVariantValue">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 5 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus" _ngcontent-eos-c103=""><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
        </button>
        <div id="product_variable_container1formVar1" class="value-container" *ngFor="let variantValue of variant.variantValues;let indexOfvalue = index;">
          <input type="text" class="form-control variant-value variantValueInput formVar1" placeholder="{{'ENTER_VALUE' | translate}}" value="{{variantValue.variantValueName}}" name="type{{indexOfelement}}" id="id{{indexOfvalue}}" (keyup)="changeVariantValue($event)">
          <button type="button" class="btn btn-outline-secondary variantValueInput delVariantBtn small-btn cancel-btn" (click)="deleteVariantValue(indexOfelement, variantValue.variantValueId)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash" _ngcontent-hyp-c84=""><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
          </button>
        </div>
      </div>
      <div class="delete-btn-container">
        <button type="button" class="btn btn-outline-secondary cancel-btn" (click)="deleteVariant(indexOfelement)">
          {{ 'REMOVE_VARIANT' | translate }}
        </button>
      </div>
    </div>

    <div class="variant-actions-container" *ngIf="variants.length < 3">
      <button type="button" class="btn btn-outline-secondary confirm-btn" (click)="addVariant()">
         {{ 'ADD_VARIANTS' | translate }}
      </button>
    </div>


    <div class="form-card">
      <div class="d-flex justify-content-end align-items-center">
        <div>
          <select class="form-select" [(ngModel)]="activeShopPoint" (change)="changeShopPoint()">
            <option value="0" selected="" ng-reflect-value="0">{{ 'ALL_BRANCHS' | translate }}</option>
            <option *ngFor="let shopPoint of shopPoints" [value]="shopPoint.shopPointId">{{shopPoint.shopPointName}}</option>
          </select>
        </div>
      </div>
      <h1 class="variantsTitle"> {{ 'VARIANT_LIST' | translate }}</h1>
      <div id="itemCombinationList">
        <div *ngFor="let item of items" class="container-fluid item">
          <div class="row">
            <div class="col-md-2 d-flex flex-column justify-content-center align-items-center">
              <h4 class="subtitle">{{objectValueJoin(item)}}</h4>
            </div>
            <div class="col-md-2 form-floating mt-4">
              <input class="form-control" id="code{{item.itemCode}}" attr.data-itemId="{{item.itemId}}" type="text" value="{{item.itemCode}}" (blur)="changeItemcode($event)">
              <label class="ms-2" for="code{{item.itemCode}}">{{'CODE' | translate}}</label>
            </div>
            <div class="col-md-2 form-floating mt-4">
              <input class="form-control" id="price{{item.itemCode}}" attr.data-itemId="{{item.itemId}}" type="number" value="{{ item.itemPrice }}" (input)="changeitemPrice($event)">
              <label class="ms-2" for="price{{item.itemCode}}">{{'PRICE' | translate}}{{'' | translate}}</label>
            </div>
            <div class="col-md-2 form-floating mt-4">
              <input class="form-control" id="discountPrice{{item.itemCode}}" attr.data-itemId="{{item.itemId}}" type="number" value="{{ item.itemDiscountPrice }}" (input)="changeitemDiscountPrice($event)">
              <label class="ms-2" for="discountPrice{{item.itemCode}}">{{ 'DISCOUNT_PRICE' | translate }}</label>
            </div>
            <div class="col-md-2 form-floating mt-4">
              <input [hidden]="activeShopPoint != 0" class="form-control quantityInput quantityMain" disabled="true" attr.data-shoppoint="0" data-item="0" value="{{item.itemTotalStock}}">
              <input *ngFor="let shopPoint of item.itemShopPoints; index as i" [hidden]="activeShopPoint != shopPoint.shopPointId" class="form-control quantityInput" attr.data-shoppoint="{{shopPoint.shopPointId}}" attr.data-itemId="{{item.itemId}}" type="number" value="{{shopPoint.itemQuantity}}" (input)="changeitemShopPointQuantity($event)">
              <label class="ms-2">{{'QUANTITY' | translate}}</label>
            </div>
            <div class="col-md-2 images-container">
              <img src="{{getImageByItem(item.itemId)}}" class="w-50" alt="item image {{item.itemId}}">
              <div class="images-list">
                <div class="image-box" cdkDragDisabled>
                  <button class="add-img btn-add-single-img" (click)="openSelectImageMoldal(item.itemId)">+</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="form-card">
      <div class="value-container">
        <label class="form-label" for="product_variable_type">
          Listado de Variantes
        </label>
        <button type="button" class="btn btn-outline-secondary confirm-btn visually-hidden">
          Actualizar Listado de Variantes
        </button>
      </div>
      <div class="info-container">
        <table class="table table-bordered table-hover" id="variantsCombinationsTable">
          <thead>
            <th scope="col" class="table-start">Item</th>
            <th scope="col">Código</th>
            <th scope="col">Cantidad</th>
            <th scope="col" >Precio</th>
            <th scope="col">Precio Descuento</th>
            <th scope="col"></th>
            <th scope="col" class="table-end"></th>
          </thead>
          <tbody id="variantsList">
            <tr *ngFor="let item of items" id="{{item.itemId}}">
              <td>{{objectValueJoin(item)}}</td>
              <td><input type="text" class="form-control" (blur)="changeItemcode($event)" value="{{item.itemCode}}"></td>
              <td><input type="number" min="0" class="form-control" (input)="changeitemQuantity($event)" value="{{item.itemQuantity}}"></td>
              <td><input type="number" min="0" class="form-control" (input)="changeitemPrice($event)" value="{{ item.itemPrice }}"></td>
              <td><input type="number" min="0" class="form-control" (input)="changeitemDiscountPrice($event)" value="{{ item.itemDiscountPrice }}"></td>
              <td><img src="{{getImageByItem(item.itemId)}}" alt="item image {{item.itemId}}"></td>
              <td class="images-container">
                <div class="images-list">
                  <div class="image-box" cdkDragDisabled>
                    <button class="add-img btn-add-single-img" (click)="openSelectImageMoldal(item.itemId)">+</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
    <div class="alert alert-danger align-items-center" [hidden]="duplicatedVariants == false" role="alert">
      <div class="d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 1024 463.43" height="18" padding=""><path d="M189.46 44.02c34.26-58.66 99.16-58.77 133.24.12l.97 1.81 175.27 304.4c33.71 56.4-1.2 113.76-66.17 112.96v.12H73.53c-.9 0-1.78-.04-2.66-.11-58.34-.79-86.64-54.22-61.9-106.84.39-.85.82-1.67 1.28-2.46l-.04-.03 179.3-309.94-.05-.03zm50.32 302.4c4.26-4.13 9.35-6.19 14.45-6.56 3.4-.24 6.8.29 9.94 1.48 3.13 1.19 6.01 3.03 8.39 5.41 6.92 6.91 8.72 17.38 4.64 26.16-2.69 5.8-7.08 9.7-12.11 11.78-3.03 1.27-6.3 1.84-9.56 1.76-3.27-.08-6.49-.82-9.41-2.18-5.02-2.33-9.3-6.43-11.7-12.2-2.65-6.36-2.27-12.96.63-19.15 1.15-2.46 2.75-4.81 4.73-6.5zm33.86-47.07c-.8 19.91-34.51 19.93-35.28-.01-3.41-34.1-12.13-110.53-11.85-142.58.28-9.87 8.47-15.72 18.94-17.95 3.23-.69 6.78-1.03 10.35-1.02 3.6.01 7.16.36 10.39 1.05 10.82 2.3 19.31 8.39 19.31 18.45l-.05 1-11.81 141.06z" fill="#212121cc"></path></svg>
        <div>
          {{ 'DUPLICATE_VARIANT_VALUES' | translate }}
        </div>
      </div>
    </div>
    <div class="buttons-container">
      <button type="button" class="btn btn-success submit-btn" [disabled]="!enabledSave" (click)="saveChanges()">
        {{ 'SAVE_CHANGES' | translate }}
      </button>
    </div>

  </div>
</div>

<div class="modal product-modal" id="productLoadingModal" tabindex="-1">
  <div class="modal-dialog product-modal-dialog" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <div class="loading">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="successEditProductModal" tabindex="-1">
  <div class="modal-dialog product-modal-dialog" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'PRODUCT_CHANGES_SAVED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" [routerLink]="['/products', productId]" class="btn btn-success confirm-btn" (click)="closeModal()">{{ 'BACK_TO_PRODUCT' | translate }}</button>
        <button type="button" class="btn btn-danger cancel-btn" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="validationModal" tabindex="-1">
  <div class="modal-dialog product-modal-dialog" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <h3 class="text-center">{{'ALERT' | translate}}!</h3>
        <p class="text-center">{{ 'EMPTY_VALUES_NOT_ALLOWED' | translate }}</p>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-danger cancel-btn" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="selectImagesModal" tabindex="-1">
  <div class="modal-dialog product-modal-dialog modal-lg" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <input type="text" id="item-image" class="d-none">
        <input type="text" id="image" class="d-none">
        <div class="images-container">
          <div cdkDropList cdkDropListOrientation="horizontal" class="image-list row" (cdkDropListDropped)="drop($event)">
            <div class="image-box col-2" cdkDragDisabled>
              <button class="add-img" (click)="selectImage(1)">+</button>
              <input id="btn-upload" type="file" class="d-none" (change)="onFileSelected($event)">
            </div>
            <div class="image-box col-2" *ngFor="let image of generalImages" cdkDrag>
              <a href="javascript:;" (click)="asociateImage(image.url)">
                <div class="image-placeholder" *cdkDragPlaceholder></div>
                <img [src]="image.url" [ngClass]="{'selected': image.url === selectedImage}"/>
                <div class="check-icon" [innerHTML]="checkIcon"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-outline-secondary confirm-btn" (click)="confirmImage()">
          {{'CONFIRM' | translate}}
        </button>
        <button type="button" class="btn btn-danger cancel-btn" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal error-confirm-modal fade" id="errorEditProductModal" tabindex="-1">
  <div class="modal-dialog error-modal-dialog" role="document">
    <div class="modal-content error-modal-content">
      <div class="modal-body error-modal-body">
        <h3 class="text-center">{{"ERROR" | translate}}</h3>
        <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
