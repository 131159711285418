import { Component, Input } from '@angular/core';
import { ITimekitComponent  } from '../timekit-tabs/timekit-tabs.component';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';

interface Day {
  name: string;
  startTime: string;
  endTime: string;
}

@Component({
  selector: 'app-timekit-settings',
  templateUrl: './timekit-settings.component.html',
  styleUrls: ['./timekit-settings.component.scss']

})
export class TimekitSettingsComponent implements ITimekitComponent {
  @Input() data: any;


  days: Day[] = [
    { name: 'Monday', startTime: '09:00', endTime: '17:00' },
    { name: 'Tuesday', startTime: '09:00', endTime: '17:00' },
    { name: 'Wednesday', startTime: '09:00', endTime: '17:00' },
    { name: 'Thursday', startTime: '09:00', endTime: '17:00' },
    { name: 'Friday', startTime: '09:00', endTime: '17:00' }
  ];

  dayTranslation: { [key: string]: string } = {
    'Monday': 'Lunes',
    'Tuesday': 'Martes',
    'Wednesday': 'Miércoles',
    'Thursday': 'Jueves',
    'Friday': 'Viernes'
  };

  projectId = '311b8064-7b8c-4df0-8786-9933e6698007';


  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
  ) { }


  getTranslatedDay(day: string): string {
    return this.dayTranslation[day] || day;
  }


  async updateAvailability() {
    try {
      const formattedConstraints = this.days.map(day => ({
        allow_day_and_time: {
          day: day.name,
          start: parseInt(day.startTime.split(':')[0], 10),
          end: parseInt(day.endTime.split(':')[0], 10),
        }
      }));

      const payload = {
        action: 'updateAvailability',
        availability_constraints: formattedConstraints
      };


      const response = await this.apiService.updateAvailability(
        this.themingService.shopId,
        this.projectId,
        formattedConstraints
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  async onSubmit() {
    try {
      const response = await this.updateAvailability();
    } catch (error) {
      console.error('Error actualizando la disponibilidad:', error);
    }
  }
}
